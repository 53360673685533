import React, { useEffect, useState } from "react";
import {
    Form,
    FormGroup,
    Label,
    Col,
    Row,
    Input,
    Tooltip,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import { toast } from "react-toastify";
import Autocomplete from "react-google-autocomplete";
import { CreateInterest } from "api/interest.api";
import { UpdateInterest } from "api/interest.api";
import { LOCATION_TYPES } from "constants/general.constant";
import { isStreetValueChecker, isExactAddressValueChecker, isCityTownChecker } from "../../common/addressValidator";
import {
    AvField,
    AvForm,
    AvRadio,
    AvRadioGroup,
} from "availity-reactstrap-validation";
import * as moment from "moment";
import { MOTIVATION_LEVEL } from "constants/general.constant";
import { STREET_SIDES } from "constants/general.constant";
import { GoogleApiWrapper } from "google-maps-react";
import { formateAddressSlug } from "common/addressHelper";

function AddInterests(props) {


    const defaultLocation = props?.editInterest?.location_type ?? "";
    const [location_type, setLocationType] = useState(defaultLocation);

    // Function to handle changes in the "Interest Location Type" dropdown
    const onLocationTypeChange = (e) => {
        const selectedLocationType = e.target.value;
        setLocationType(selectedLocationType);
        // You can add additional logic here based on the selected location type if needed
    };
    // Default array
    const { editInterest } = props;

    const defaultFormData = {
        from_val: editInterest?.from_val,
        street_side: editInterest?.street_side,
        commitment_level: editInterest?.commitment_level,
        to_val: editInterest?.to_val,
        google_address: editInterest?.google_address?.[0] ?? "",
        address: editInterest?.address ?? "",
        title: editInterest?.title ?? "",
        location_type: editInterest?.location_type ?? "",
        address_slug: editInterest?.address_slug,
        street_number: editInterest?.street_number,
        street_name_short: editInterest?.street_name_short,
        latitude: editInterest?.latitude,
        longitude: editInterest?.longitude,
    };

    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const [modal, setModal] = useState(false);

    const [formData, setFormData] = useState(defaultFormData);
    const [error, setError] = useState(false);
    const [isCityTown, setIsCityTown] = useState(true);
    const [StreetValue, setStreetValue] = useState(true);
    const [exactValue, setExactValue] = useState(true);
    const [commitmentLevelError, setCommitmentLevelError] = useState(false);
    const [fromToError, setFromToError] = useState(false);
    const [update, setUpdate] = useState(moment());

    useEffect(() => { }, [update]);

    const toggle = (value) => {
        setTooltipOpen(value === tooltipOpen ? "" : value);
    };

    const toggleModal = () => setModal(!modal);

    const handleGoogleAddress = (place) => {
        let tempData = formData;
        if (place) {
            const google_address = place;
            const latitude = place?.geometry?.location?.lat();
            const longitude = place?.geometry?.location?.lng();
            const address = place?.formatted_address;

            tempData.google_address = google_address;
            tempData.address = address;
            tempData.title = address;
            tempData.latitude = latitude;
            tempData.longitude = longitude;

            setFormData(tempData);
            setError(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    const handleAddressSave = async () => {
        let interestData = formData;

        console.log("interestData", interestData)
        try {
            let response = {};
            if (props?.editInterest?.edit) {
                for (const key in interestData) {
                    if (Object.hasOwnProperty.call(interestData, key)) {
                        const element = interestData[key];
                        if (!element) {
                            delete interestData[key];
                        }
                    }
                }
                if (interestData.location_type !== "STREET") {
                    interestData.from_val = null;
                    interestData.to_val = null;
                    interestData.street_side = null;
                }
                response = await UpdateInterest(interestData, props?.editInterest?.id);
            } else {
                response = await CreateInterest(interestData);
            }

            if (response && response.data && response.data.success) {
                if (props.updateAddInterestsData) {
                    props.updateAddInterestsData(response.data.data.interest);
                }
                toast.success(response.data?.message);
                if (props.close) {
                    props.close(interestData);
                }
                toggleModal();
            } else {
                toast.error(response.data?.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onDataChange = async (e) => {
        let { name, value, checked } = e.target;

        if (["checkbox_name"].includes(name)) {
            value = checked ? "1" : "0";
        }
        formData[name] = value;

        const { addressSlug, streetNumber, streetNameShort } = await formateAddressSlug(formData.google_address)

        formData.address_slug = addressSlug;
        formData.street_number = streetNumber;
        formData.street_name_short = streetNameShort;

        setFormData(formData);
        setUpdate(moment());
    };

    const handleReopenFilesModal = (e) => {
        if (formData.location_type === "CITY/TOWN" && !isCityTown) {
            alert("Please enter a valid address. Specify City/Town only.");
            return;
        }
        else if (formData.location_type === "STREET" && !StreetValue) {
            alert("Please enter a valid address. Specify Street Address only.");
            return;
        }
        else if (formData.location_type === "EXACT_ADDRESS" && !exactValue) {
            alert("Please enter a valid address. Specify Exact Address only.");
            return;
        }
        else if (!formData?.google_address) {
            setError(true);
        } else if (
            (formData.from_val && formData.from_val !== 0 && !formData.to_val) ||
            (formData.to_val && formData.to_val !== 0 && !formData.from_val)
        ) {
            setFromToError(true);
        } else if (
            !formData?.commitment_level &&
            formData?.commitment_level !== ""
        ) {
            setCommitmentLevelError(true);
        } else {
            setError(false);
            setFromToError(false);
            setCommitmentLevelError(false);
            if (
                props &&
                props?.editInterest &&
                Object.keys(props.editInterest).length > 0
            ) {
                handleAddressSave();
            } else {
                toggleModal();
            }
        }
    };

    const onGoogleDataChange = (value) => {
        setFormData({ ...formData, address: value });
    };

    const tooltipStyle = {
        fontSize: "18px",
        maxWidth: "100%",
        textAlign: "left",
    };

    return (
        <>
            <div>
                <AvForm onValidSubmit={(e) => handleReopenFilesModal(e)}>
                    {props?.editInterest &&
                        Object.keys(props.editInterest).length > 0 && (
                            <>
                                <FormGroup row className="custom-form-group">
                                    <Label for="title" sm={3}>
                                        Title
                                    </Label>
                                    <Col sm={9}>
                                        <Input
                                            id="title"
                                            name="title"
                                            placeholder="Please enter the title"
                                            type="text"
                                            value={formData.title}
                                            onChange={(e) => onDataChange(e)}
                                        />
                                    </Col>
                                </FormGroup>
                            </>
                        )}

                    <FormGroup row className="custom-form-group">
                        <Label for="Condition" sm={3}>
                            Interest Location Type*
                        </Label>
                        <Col sm={9}>
                            <div style={{ width: "100%" }} className="d-flex">
                                <div style={{ width: "96%" }}>
                                    <AvField
                                        type="select"
                                        name="location_type"
                                        id="location_type"
                                        onChange={(e) => {
                                            onLocationTypeChange(e);
                                            onDataChange(e);
                                        }}
                                        value={formData.location_type}
                                        required
                                        errorMessage="Please select a location type"
                                    >
                                        <option value="" disabled defaultValue>
                                            Select Location
                                        </option>
                                        {LOCATION_TYPES?.map(({ value, label }, index) => {
                                            return (
                                                <option value={value} key={index}>
                                                    {label}
                                                </option>
                                            );
                                        })}
                                    </AvField>
                                </div>
                                <div>
                                    <span className="mt-2 ml-1" id="condition">
                                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                                    </span>
                                    <Tooltip
                                        placement="top"
                                        isOpen={tooltipOpen === "condition"}
                                        target="condition"
                                        toggle={() => toggle("condition")}
                                        style={tooltipStyle}
                                    >
                                        <p className="tooltip-details">
                                            Exact Address - A specific address
                                        </p>
                                        <p className="tooltip-details">
                                            Street side - You can add address range and side
                                        </p>
                                        <p className="tooltip-details">Neighbourhood - tbd</p>
                                        <p className="tooltip-details">
                                            Self-defined area - You can draw your own polygon
                                        </p>
                                    </Tooltip>
                                </div>
                            </div>
                        </Col>
                    </FormGroup>
                    <FormGroup row className="custom-form-group">
                        {formData.location_type === "CITY/TOWN" ? (
                            <Label for="address" sm={3}>
                                City / Town *
                            </Label>
                        ) : (
                            <Label for="address" sm={3}>
                                Address *
                            </Label>
                        )}
                        <Col sm={9}>
                            <div style={{ width: "100%" }} className="d-flex">
                                <div style={{ width: "96%" }}>
                                    <Autocomplete
                                        size="sm"
                                        className={`form-control `}
                                        onPlaceSelected={(place) => {
                                            handleGoogleAddress(place);
                                            let isCityTownValue = isCityTownChecker(place)
                                            setIsCityTown(isCityTownValue);
                                            let streetDataValue = isStreetValueChecker(place)
                                            setStreetValue(streetDataValue);
                                            let exactAddressDataValue = isExactAddressValueChecker(place)
                                            setExactValue(exactAddressDataValue);
                                        }}
                                        options={{
                                            types: ["geocode", "establishment"],
                                        }}
                                        value={formData?.address}
                                        onChange={(e) => onGoogleDataChange(e.target.value)}
                                        name="address"
                                        placeholder={
                                            formData.location_type === "CITY/TOWN"
                                                ? "Please enter the city / town"
                                                : "Please enter address"
                                        }
                                        onKeyDown={handleKeyDown}
                                    />
                                    {error && (
                                        <div style={{ color: "#fb6340" }}>
                                            {formData.location_type === "CITY/TOWN"
                                                ? "City / Town is required."
                                                : "Address is required."}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {["STREET", "EXACT_ADDRESS"].includes(
                                        formData.location_type
                                    ) && (
                                            <>
                                                <span className="mt-2 ml-1" id="conditi">
                                                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                                                </span>
                                                <Tooltip
                                                    placement="top"
                                                    isOpen={tooltipOpen === "conditi"}
                                                    target="conditi"
                                                    toggle={() => toggle("conditi")}
                                                    style={tooltipStyle}
                                                >
                                                    {formData.location_type === "EXACT_ADDRESS" ? (
                                                        <p className="tooltip-details">
                                                            Enter the address of your Interest
                                                        </p>
                                                    ) : null}

                                                    {formData.location_type === "STREET" ? (
                                                        <p className="tooltip-details">
                                                            Enter the name of the street you are Interested in
                                                        </p>
                                                    ) : null}
                                                </Tooltip>
                                            </>
                                        )}
                                </div>
                            </div>
                        </Col>
                    </FormGroup>
                    <FormGroup row className="custom-form-group">
                        <Label for="Commitment Level" sm={3}>
                            Commitment Level *
                        </Label>
                        <Col sm={9}>
                            <div style={{ width: "100%" }} className="d-flex">
                                <div style={{ width: "96%" }}>
                                    <AvField
                                        type="select"
                                        name="commitment_level"
                                        aria-label="Please enter commitment level"
                                        value={formData.commitment_level}
                                        onChange={(e) => onDataChange(e)}
                                    >
                                        <option value="" disabled defaultValue>
                                            Select commitment level
                                        </option>
                                        {MOTIVATION_LEVEL &&
                                            MOTIVATION_LEVEL?.length &&
                                            MOTIVATION_LEVEL.map(({ value, label }, index) => (
                                                <option value={value} key={index}>
                                                    {label}
                                                </option>
                                            ))}
                                    </AvField>
                                </div>

                                <div style={{ width: "4%" }}>
                                    <span className="mt-2 ml-1" id="commitmentLevels">
                                        <i className="fa fa-info-circle" aria-hidden="true"></i>
                                    </span>
                                    <Tooltip
                                        placement="top"
                                        isOpen={tooltipOpen === "commitmentLevels"}
                                        target="commitmentLevels"
                                        toggle={() => toggle("commitmentLevels")}
                                        style={{ textAlign: "left" }}
                                    >
                                        <p className="tooltip-details">1 = Very low motivation</p>
                                        <p className="tooltip-details">2 = Low motivation</p>
                                        <p className="tooltip-details">3 = Medium motivation</p>
                                        <p className="tooltip-details">4 = High motivation</p>
                                    </Tooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 20,
                                    left: "42%",
                                    transform: "translate(-100%, 100%)",
                                }}
                            >
                                {commitmentLevelError && (
                                    <div style={{ color: "#fb6340" }}>
                                        Commitment Level is required.
                                    </div>
                                )}
                            </div>
                        </Col>
                    </FormGroup>
                    {location_type === "STREET" && (
                        <>
                            <FormGroup row className="custom-form-group">
                                <Label for="from_val" sm={3}>
                                    Address range between
                                </Label>
                                <Col sm={9}>
                                    <div style={{ width: "100%" }} className="d-flex">
                                        <div style={{ width: "96%" }}>
                                            <Row>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <AvField
                                                            type="number"
                                                            className="form-control"
                                                            id="from_val"
                                                            name="from_val"
                                                            placeholder="From"
                                                            step="1"
                                                            onChange={(e) => onDataChange(e)}
                                                            value={formData.from_val ?? ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <div style={{ marginTop: "9px" }}>and</div>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <AvField
                                                            type="number"
                                                            className="form-control"
                                                            id="to_val"
                                                            name="to_val"
                                                            placeholder="To"
                                                            step="1"
                                                            onChange={(e) => onDataChange(e)}
                                                            value={formData.to_val ?? ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <span className="mt-2 ml-1" id="cond">
                                                <i className="fa fa-info-circle" aria-hidden="true"></i>
                                            </span>
                                            <Tooltip
                                                placement="top"
                                                isOpen={tooltipOpen === "cond"}
                                                target="cond"
                                                toggle={() => toggle("cond")}
                                                style={tooltipStyle}
                                            >
                                                <p className="tooltip-details">
                                                    Optionally indicate whether your interest is only for
                                                    a specific range of address numbers.
                                                </p>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            position: "absolute",
                                            bottom: 20,
                                            left: "42%",
                                            transform: "translate(-100%, 100%)",
                                        }}
                                    >
                                        {fromToError && (
                                            <div style={{ color: "#fb6340" }}>
                                                Enter both From and To value.
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </FormGroup>
                            <FormGroup row className="custom-form-group">
                                <Label for="Address" sm={3}>
                                    Side of street
                                </Label>
                                <Col sm={9}>
                                    <div style={{ width: "100%" }} className="d-flex">
                                        <div style={{ width: "96%" }}>
                                            <AvRadioGroup
                                                name="street_side"
                                                onChange={(e) => onDataChange(e)}
                                                value={formData.street_side ?? ""}
                                            >
                                                {STREET_SIDES?.map(({ value, label }, index) => {
                                                    return <AvRadio label={label} value={value} />;
                                                })}
                                            </AvRadioGroup>
                                        </div>
                                        <div>
                                            <span className="mt-2 ml-1" id="conditions">
                                                <i className="fa fa-info-circle" aria-hidden="true"></i>
                                            </span>
                                            <Tooltip
                                                placement="top"
                                                isOpen={tooltipOpen === "conditions"}
                                                target="conditions"
                                                toggle={() => toggle("conditions")}
                                                style={tooltipStyle}
                                            >
                                                <p className="tooltip-details">
                                                    Optionally indicate whether your interest is only for
                                                    the even or odd addressed side of the street.
                                                </p>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Col>
                            </FormGroup>
                        </>
                    )}
                    <div className="text-right">
                        <Button
                            color="primary"
                            type="button"
                            size="sm"
                            outline
                            onClick={() => props.close(true)}
                        >
                            <i className="fa fa-times"></i>
                            <span>Cancel</span>
                        </Button>

                        <Button
                            color="primary"
                            type="submit"
                            size="sm"
                            outline
                            style={{ marginLeft: "10px" }}
                        >
                            <i className="fa fa-save"></i>
                            <span>Save</span>
                        </Button>
                    </div>
                </AvForm>
            </div>

            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Please add the Title</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup row>
                            <Label for="title" sm={2}>
                                Title
                            </Label>
                            <Col sm={10}>
                                <Input
                                    id="title"
                                    name="title"
                                    placeholder="Please enter the title"
                                    type="text"
                                    value={formData.title}
                                    onChange={(e) => onDataChange(e)}
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleAddressSave}>
                        save
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_ADDRESS_KEY,
})(AddInterests);
