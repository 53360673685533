import React, { useState, useEffect } from "react";
import { Button, Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { CreateUser, EditUser } from "../../../api/admin.api";

export default function AddUser(props) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [spinner, setSpinner] = useState(false);

  const validationSchema = Yup.object().shape({
    user_name: Yup.string().required("User name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),

    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&#.,%_-]{6,}$/,
        "Password must contain at least 6 characters, one uppercase letter, one lowercase letter and one number"
      ),
    confirmPassword: Yup.string()
      .min(6, "Confirm password must be at least 6 characters")
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
  });

  const editValidationSchema = Yup.object().shape({
    user_name: Yup.string().required("User name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      props?.editUser?.edit ? editValidationSchema : validationSchema
    ),
  });

  useEffect(() => {
    if (props && props?.editUser && props?.editUser?.edit) {
      setValue("email", props?.editUser?.email);
      setValue("user_name", props?.editUser?.user_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //complete registration
  const onSubmit = async (data) => {
    try {
      let response = {};
      setSpinner(true);
      setIsDisabled(false);
      if (props?.editUser?.edit) {
        response = await EditUser(
          {
            email: data.email,
            //user_name: data.user_name,
          },
          props?.editUser?.id
        );
      } else {
        response = await CreateUser({
          email: data.email,
          user_name: data.user_name,
          password: data.password,
          confirmPassword: data.confirmPassword,
        });
      }
      if (response && response.data && response.data.success) {
        toast.success(response.data?.message);
        props.close(true);
        reset();
      } else {
        toast.error(response.data?.message);
      }
      setSpinner(false);
      setIsDisabled(true);
    } catch (error) {
      setSpinner(false);
      setIsDisabled(true);
      toast.error(
        error?.response?.data?.errors.length !== 0
          ? error?.response?.data?.errors[0]?.message
          : error.response.data.message
      );
    }
  };
  return (
    <>
      <Form role="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Email </label>
          <input
            name="email"
            type="text"
            {...register("email")}
            placeholder="Please enter email"
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.email?.message}</div>
        </div>
        <div className="form-group">
          <label>user_name </label>
          <input
            name="user_name"
            type="text"
            disabled={props?.editUser?.edit}
            {...register("user_name")}
            placeholder="Please enter user_name"
            className={`form-control ${errors.user_name ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.user_name?.message}</div>
        </div>

        <div className={`form-group ${props?.editUser?.edit ? "d-none" : ""}`}>
          <label>Password </label>
          <input
            name="password"
            type="password"
            {...register("password")}
            placeholder="Please enter password"
            className={`form-control ${errors.password ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.password?.message}</div>
        </div>
        <div className={`form-group ${props?.editUser?.edit ? "d-none" : ""}`}>
          <label>Confirm password </label>
          <input
            name="confirmPassword"
            type="password"
            {...register("confirmPassword")}
            placeholder="Please enter confirm password"
            className={`form-control ${
              errors.confirmPassword ? "is-invalid" : ""
            }`}
          />
          <div className="invalid-feedback">
            {errors.confirmPassword?.message}
          </div>
        </div>
        <div className="text-right">
          <Button
            color="primary"
            type="submit"
            size="sm"
            outline
            disabled={spinner}
          >
            <i className="fa fa-save"></i>
            {spinner && !isDisabled ? (
              <Spinner size="sm" className="mr-1">
                Loading...
              </Spinner>
            ) : (
              ""
            )}
            {""}
            <span>
              {props?.editUser?.edit ? "Update Account" : "Create Account"}
            </span>
          </Button>
        </div>
      </Form>
    </>
  );
}
