import { Button, Form } from "reactstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { verifyOTP } from "../../../api/auth.api";
import { toast } from "react-toastify";

function OtpVerify(props) {
  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required("OTP is required")
      .length(6, "OTP must be exactly 6 characters"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await verifyOTP({
        email: props.email,
        otp: data.otp,
      });
      if (response && response.data && response.data.success) {
        toast.success(response.data?.message);
        props.otpVerification(true);
      } else {
        toast.error(response.data?.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.errors.length !== 0
          ? error?.response?.data?.errors[0]?.message
          : error.response.data.message
      );
    }
  };

  return (
    <>
      <Form role="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Verification Code </label>
          <input
            name="otp"
            type="text"
            {...register("otp")}
            placeholder="Please enter otp"
            className={`form-control ${errors.otp ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.otp?.message}</div>
        </div>
        <div>
          <Button color="primary" type="submit">
            Verify
          </Button>
        </div>
      </Form>
    </>
  );
}

export default OtpVerify;
