import MapView from "./propertyView/MapView";
import React, { useEffect, useState } from "react";
import TableView from "./propertyView/tableView";
import Header from "components/Headers/Header";
import { filterPropertyAndInterest } from "api/search.api";
import { toast } from "react-toastify";
import { PROPERTY_FILTER } from "constants/general.constant";
import { GetUser } from "../common/helper";
import { INTEREST_FILTER } from "constants/general.constant";
import Loader from "components/spinner/spinner";
import { GoogleApiWrapper } from "google-maps-react";

function Home(props) {
  const [propertyView, setPropertyView] = useState();
  const [filterData, setFilterData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [event, setEvent] = useState({});
  const [type, setType] = useState({});
  const [data, setData] = useState({
    pageSize: 30,
    pageNumber: 1,
    type: PROPERTY_FILTER.ALL_PROPERTY,
  });
  const currentUser = GetUser();

  useEffect(() => {
    getAllProperties(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadData = (eventTemp) => {
    setEvent(eventTemp);
    getAllProperties({ ...data, ...eventTemp });
  };

  const getAllProperties = async (data) => {
    try {
      setSpinner(true);
      const response = await filterPropertyAndInterest(data);
      setSpinner(false);
      if (
        response &&
        response.data &&
        response.data.success &&
        response.data.data
      ) {
        let filterTemp = response.data?.data?.filterData;
        setFilterData(filterTemp);
      } else {
        toast.error(response.data.data.message);
      }
    } catch (error) {
      setSpinner(false);
      toast.error(
        error?.response?.data?.errors.length !== 0
          ? error?.response?.data?.errors[0]?.message
          : error.response.data.message
      );
    }
  };

  const filterType = (type) => {
    try {
      data.type = type ?? PROPERTY_FILTER.MY_PROPERTY;
      if (
        type &&
        (type === PROPERTY_FILTER.MY_PROPERTY ||
          type === INTEREST_FILTER.MY_INTERESTS ||
          type === PROPERTY_FILTER.MATCH_PROPERTY ||
          type === INTEREST_FILTER.MATCH_INTERESTS)
      ) {
        data.user_id = currentUser.id;
      }

      setData(data);
      getAllProperties({ ...data, ...event });

      if (["ALL_INTERESTS", "MY_INTERESTS", "MATCH_INTERESTS"].includes(type)) {
        setType(type);
      } else {
        setType(type);
      }
    } catch (error) { }
  };

  const viewType = (value) => {
    setPropertyView(value);
  };

  return (
    <>
      <Loader isShow={spinner}></Loader>
      <Header
        filter={(e) => filterType(e)}
        type={(type) => viewType(type)}
      ></Header>

      <div
        className="d-grid gap-2 d-md-flex justify-content-md-end"
      ></div>

      <div className="mt-2">
        <div
          className="d-grid gap-2 d-md-flex justify-content-md-end"
        ></div>
        {propertyView === "TABLE" ? (
          <TableView properties={{ filterData, data }} type={type}></TableView>
        ) : (
          <MapView
            properties={{ filterData }}
            reloadData={(e) => reloadData(e)}
            google={props.google}
            type={type}
          ></MapView>
        )}
      </div>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_ADDRESS_KEY,
})(Home);
