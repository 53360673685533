import React, { useState } from 'react';
import { Table, Tooltip } from 'reactstrap';
import "./interestTooltip.css";

const InterestTooltip = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div>
      <span
        className="mt-2 ml-1"
        id="interests"
        onMouseEnter={toggle}
        onMouseLeave={toggle}
      >
        <i className="fa fa-info-circle" aria-hidden="true"></i>
      </span>
      <Tooltip
        className="in"
        id="tooltip-top"
        placement="right"
        isOpen={tooltipOpen}
        target="interests"
        toggle={toggle}
        style={{
          maxWidth: "500px",
          maxHeight: "400px",
          // backgroundColor: "white",
        }}
      >
        <p className="tooltip-details" style={{ textAlign: 'left' }}><h4 className='font-color-white'>Help with creating an Interest</h4></p>
        <p className="tooltip-details" style={{ textAlign: 'left' }}>
          <h6 className='font-color-white'>You can create an Interest that is as specific as a<br /> single address, or
            as general as a neighbourhood or a drawn area.</h6>
        </p>
        <Table borderless className='font-color-white'>
          <tr>
            <th><u>Location Type:</u></th>
            <th></th>
            <th><u>Examples:</u></th>
          </tr>
          <tbody>
            <tr>
              <td >Exact address</td>
              <td>Only properties with the same <br /> street address will be mattched.</td>
              <td>You are interested in a<br />specific house.</td>
            </tr>
            <tr>
              <td>Street</td>
              <td>Properties on a given street.<br />You can optionally add an <br /> address range or a "side", and <br />only those will be mattched.</td>
              <td>You are interested in a<br />specific block on a street.<br />One side backs onto<br />greenspace.</td>
            </tr>
            <tr>
              <td>Neighbourhood</td>
              <td>All properties in the given<br />neighbourhood will be matched.<br />Note that some cities do not<br />support neighbourhoods.</td>
              <td></td>
            </tr>
            <tr>
              <td>Area</td>
              <td>All properties within the area<br />you draw will be matched.</td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </Tooltip>
    </div>
  );
};

export default InterestTooltip;
