import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Form,
    Spinner,
    Tooltip,
    FormGroup,
    Label,
    Modal,
    ModalBody,
} from "reactstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { CreateProperty, UpdateProperty } from "api/properties.api";
import Autocomplete from "react-google-autocomplete";
import { handleGoogleAddressProcess } from "common/gecodeAddress";
import { PropertyLimit } from "api/admin.api";
import { PatchDate } from "common/helper";
import {
    MOTIVATION_LEVEL,
    STATUS,
    LISTING_TYPE,
    BUILDING_TYPE,
    OWNERSHIP_TYPE,
    URL_VALIDATION,
    CONDITION,
} from "constants/general.constant";
import {
    PROPERTY_MESSAGES,
    COMMON_MESSAGES,
} from "constants/messages.constant";
import "./property.css";
import { GoogleApiWrapper } from "google-maps-react";

function AddProperties(props) {
    const [isDisabled, setIsDisabled] = useState(false);
    const [propertyLimitError, setPropertyLimitError] = useState(0);
    const [propertyLimitCount, setPropertyLimitCount] = useState(0);

    const [processing, setProcessing] = useState(false);
    const [showAddressSuggestionPopup, setShowAddressSuggestionPopup] = useState(false);

    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    useEffect(() => {
        if (props?.editProperty && Object.keys(props?.editProperty).length) {
            setPropertyValue(props?.editProperty);
            handleGoogleAddress(props?.editProperty?.google_address[0]);
        }

        if (props?.address && Object.keys(props?.address).length) {
            setValue("address", props.address.formatted_address);
        }
        if (!(props?.editProperty && Object.keys(props?.editProperty).length)) {
            setValue("status", STATUS[0].value);
            setValue("start_date", PatchDate(new Date()));
        }

        if (!props?.editProperty?.id) {
            getSiteSettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggle = (value) => {
        setTooltipOpen(value === tooltipOpen ? "" : value);
    };

    const getSiteSettings = async () => {
        try {
            setProcessing(true);
            const value = JSON.parse(localStorage.getItem("user"));

            const test = await PropertyLimit(value.id);
            setPropertyLimitError(test.data.data.propertyLimitError);
            setPropertyLimitCount(test.data.data.propertyLimitCount);
            setProcessing(false);
        } catch (error) {
            console.log(error);
        }
    };
    const hideInvalidAddressModal = () => {
        setShowAddressSuggestionPopup(!showAddressSuggestionPopup);
        // Focus on the input field when the component mounts
        // inputRef.current.focus();
    };
    const validationSchema = Yup.object().shape({
        address: Yup.string().required("Address is required"),
        addr_number: Yup.string().optional().max(20, "Must be 20 number allowed"),
        addr_unit: Yup.string().max(20, "Maximum 20 character allowed").optional(),
        addr_street: Yup.string()
            .optional()
            .max(50, "Maximum 50 character allowed"),
        addr_street_type: Yup.string()
            .optional()
            .max(20, "MAximum 20 character allowed"),
        city: Yup.string()
            .optional()
            .max(50, "Maximum 50 character allowed"),
        province: Yup.string()
            .optional()
            .max(50, "Maximum 50 character allowed"),
        country: Yup.string()
            .optional()
            .max(50, "Maximum 50 character allowed"),
        latitude: Yup.string().required("Latitude is required"),
        longitude: Yup.string().required("Longitude is required"),
        building_type: Yup.string()
            .required("Property type is required")
            .notOneOf(["DEFAULT"], "Property type is required"),
        ownership_type: Yup.string()
            .required("ownership_type is required")
            .notOneOf(["DEFAULT"], "Ownership Type is required"),
        listing_type: Yup.string()
            .required("Listing type is required")
            .notOneOf(["DEFAULT"], "Listing type is required"),
        start_date: Yup.date()
            .required("Start date is required")
            .typeError("The value must be a date (DD-MM-YYYY)"),
        price: Yup.string()
            .optional()
            .test("is-number", "Please enter a valid number", (value) => {
                return !isNaN(Number(value));
            }),
        commitment_level: Yup.number()
            .required("Commitment level level is required")
            .notOneOf([1000], "Commitment level is required")
            .typeError("The value must be a number"),
        status: Yup.string()
            .required("Status is required")
            .notOneOf(["DEFAULT"], "Status is required"),
        property_condition: Yup.string()
            .required("Condition is required")
            .notOneOf(["DEFAULT"], "Condition is required"),
        frontage: Yup.string().optional(),
        zoning: Yup.string().optional(),
        outsideURL: Yup.string().optional().trim(),
        google_address: Yup.object().optional(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        setError,
    } = useForm({ resolver: yupResolver(validationSchema) });

    const onSubmit = async (data) => {
        try {
            if (!data.addr_unit) {
                delete data.addr_unit;
            }
            if (!data.zoning) {
                delete data.zoning;
            }
            if (!data.frontage) {
                delete data.frontage;
            }
            if (data.status === "DEFAULT") {
                delete data.status;
            }
            if (data.listing_type === "DEFAULT") {
                delete data.listing_type;
            }
            if (!data.price) {
                delete data.price;
            }
            if (data.building_type === "DEFAULT" || !data.building_type) {
                delete data.building_type;
            }
            if (data.ownership_type === "DEFAULT" || !data.ownership_type) {
                delete data.ownership_type;
            }
            if (data.addr_street_type === "DEFAULT" || !data.addr_street_type) {
                delete data.addr_street_type;
            }
            if (data.property_condition === "DEFAULT" || !data.property_condition) {
                delete data.property_condition;
            }
            if (!data.outsideURL) {
                delete data.outsideURL;
            } else {
                const valid = URL_VALIDATION.test(data.outsideURL);
                if (!valid) {
                    setError("outsideURL", {
                        message: COMMON_MESSAGES.INVALID_URL,
                    });
                    return;
                }
            }
            if (data.google_address) {
                data.google_address = [data.google_address];
                let streetData = data?.google_address[0]?.address_components?.find(
                    (element) => element.types.includes("street_number")
                );
                if (!streetData?.long_name) {
                    setShowAddressSuggestionPopup(true);
                    data.google_address = [];
                    data.address = "";
                    return false;
                }
            }

            let response = {};
            setIsDisabled(true);

            if (data.latitude) {
                data.latitude = parseFloat(data.latitude).toFixed(7)
            }
            if (data.longitude) {
                data.longitude = parseFloat(data.longitude).toFixed(7)
            }

            if (props?.editProperty?.user_id) {
                response = await UpdateProperty(data, props?.editProperty?.id);
            } else {
                response = await CreateProperty(data);
            }
            if (response && response.data && response.data.success) {
                let data = response.data.data;
                toast.success(
                    props?.editProperty?.edit
                        ? PROPERTY_MESSAGES?.UPDATE
                        : PROPERTY_MESSAGES?.CREATE
                );
                if (props?.editProperty?.edit) {
                    data.editProperty = true;
                } else {
                    data.editProperty = false;
                }
                props.close(data);
                reset();
            } else {
                toast.error(response.data?.message);
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.errors.length !== 0
                    ? error?.response?.data?.errors[0]?.message
                    : error.response.data.message
            );
        }
        setIsDisabled(false);
    };

    const handleGoogleAddress = (place) => {
        place = JSON.parse(JSON.stringify(place))

        handleGoogleAddressProcess(place, async function (googleAddressTemp) {
            googleAddressTemp.googleAddressResponse = place;
            setPropertyValue(googleAddressTemp);

            let streetData = place?.address_components?.find(
                (element) => element.types.includes("street_number")
            );

            if (!streetData?.long_name) {
                setShowAddressSuggestionPopup(true);
                reset()
            }
        });
    };

    const setPropertyValue = (data) => {
        if (data?.edit) {
            if (data?.addr_unit) {
                setValue("addr_unit", data?.addr_unit);
            }
            if (data?.building_type) {
                setValue("building_type", data.building_type);
            }
            if (data?.ownership_type) {
                setValue("ownership_type", data.ownership_type);
            }
            if (data?.listing_type) {
                setValue("listing_type", data.listing_type);
            }
            if (data?.start_date) {
                setValue("start_date", PatchDate(data.start_date));
            }
            if (data?.price) {
                setValue("price", data.price);
            }
            if (data?.commitment_level) {
                setValue("commitment_level", data.commitment_level);
            }
            if (data?.status) {
                setValue("status", data.status);
            }
            if (data?.property_condition) {
                setValue("property_condition", data.property_condition);
            }
            if (data?.frontage) {
                setValue("frontage", data.frontage);
            }
            if (data?.zoning) {
                setValue("zoning", data.zoning);
            }
            if (data?.outside_url) {
                setValue("outsideURL", data.outside_url);
            }
        }
        if (data?.addr_number) {
            setValue("addr_number", data.addr_number);
        }
        if (data?.addr_street_type) {
            setValue("addr_street_type", data.addr_street_type);
        }
        if (data?.province) {
            setValue("province", data.province);
        }
        if (data?.addr_street) {
            setValue("addr_street", data.addr_street);
        }
        if (data?.city) {
            setValue("city", data.city);
        }
        if (data?.country) {
            setValue("country", data.country);
        }
        if (data?.latitude) {
            setValue("latitude", data.latitude);
        }
        if (data?.longitude) {
            setValue("longitude", data.longitude);
        }
        if (data?.address) {
            setValue("address", data.address);
        }
        if (data?.googleAddressResponse) {
            setValue("google_address", data?.googleAddressResponse);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    };

    const tooltipStyle = {
        fontSize: "18px",
        maxWidth: "100%",
        textAlign: "left",
    };
    return (
        <>
            {!processing && (
                <>
                    {!propertyLimitError ? (
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="custom-form-form-group">
                                <FormGroup row className="custom-form-group">
                                    <Label for="Address" sm={3}>
                                        Address *
                                    </Label>
                                    <Col sm={9}>
                                        <Autocomplete
                                            size="sm"
                                            className={`form-control ${errors.address ? "is-invalid" : ""
                                                }`}
                                            onPlaceSelected={(place) => {
                                                handleGoogleAddress(place);
                                            }}
                                            options={{
                                                types: ["geocode", "establishment"],
                                            }}
                                            defaultValue={
                                                props?.editProperty?.address
                                                    ? props?.editProperty?.address
                                                    : props?.address?.formatted_address
                                            }
                                            {...register("address")}
                                            placeholder="Please enter address"
                                            onKeyDown={handleKeyDown}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.address?.message}
                                        </div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="custom-form-group">
                                    <Label for="Unit" sm={3}>
                                        Unit
                                    </Label>
                                    <Col sm={3}>
                                        <input
                                            name="addr_unit"
                                            type="text"
                                            {...register("addr_unit")}
                                            className={`form-control ${errors.addr_unit ? "is-invalid" : ""
                                                }`}
                                            placeholder="Please enter unit"
                                            onKeyDown={handleKeyDown}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.addr_unit?.message}
                                        </div>
                                    </Col>
                                </FormGroup>
                            </div>
                            <div className="custom-form-form-group">
                                <FormGroup row className="custom-form-group">
                                    <Label for="Property Type" sm={3}>
                                        Building Type *
                                    </Label>
                                    <Col sm={9}>
                                        <select
                                            name="building_type"
                                            {...register("building_type")}
                                            className={`form-control ${errors.building_type ? "is-invalid" : ""
                                                }`}
                                            defaultValue={"DEFAULT"}
                                            aria-label="Please enter direction type"
                                        >
                                            <option value="DEFAULT" disabled>
                                                Select property type
                                            </option>
                                            {BUILDING_TYPE &&
                                                BUILDING_TYPE?.length &&
                                                BUILDING_TYPE.map(({ value, label }, index) => (
                                                    <option value={value} key={index}>
                                                        {label}
                                                    </option>
                                                ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            {errors.building_type?.message}
                                        </div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="custom-form-group">
                                    <Label for="Listing Type" sm={3}>
                                        Listing Type *
                                    </Label>
                                    <Col sm={9}>
                                        <select
                                            name="listing_type"
                                            {...register("listing_type")}
                                            className={`form-select form-control ${errors.listing_type ? "is-invalid" : ""
                                                }`}
                                            aria-label="Please enter listing type"
                                            defaultValue={"DEFAULT"}
                                        >
                                            <option value="DEFAULT" disabled>
                                                Select listing type
                                            </option>
                                            {LISTING_TYPE &&
                                                LISTING_TYPE?.length &&
                                                LISTING_TYPE.map(({ value, label }, index) => (
                                                    <option value={value} key={index}>
                                                        {label}
                                                    </option>
                                                ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            {errors.listing_type?.message}
                                        </div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="custom-form-group">
                                    <Label for="Ownership Type" sm={3}>
                                        Ownership Type *
                                    </Label>
                                    <Col sm={9}>
                                        <select
                                            name="ownership_type"
                                            {...register("ownership_type")}
                                            className={`form-select form-control ${errors.ownership_type ? "is-invalid" : ""
                                                }`}
                                            aria-label="Please enter ownership type"
                                            defaultValue={"DEFAULT"}
                                        >
                                            <option value="DEFAULT" disabled>
                                                Select ownership type
                                            </option>
                                            {OWNERSHIP_TYPE &&
                                                OWNERSHIP_TYPE?.length &&
                                                OWNERSHIP_TYPE.map(({ value, label }, index) => (
                                                    <option value={value} key={index}>
                                                        {label}
                                                    </option>
                                                ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            {errors.ownership_type?.message}
                                        </div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="custom-form-group">
                                    <Label for="Condition" sm={3}>
                                        Condition *
                                    </Label>
                                    <Col sm={9}>
                                        <div style={{ width: "100%" }} className="d-flex">
                                            <div style={{ width: "96%" }}>
                                                <select
                                                    name="property_condition"
                                                    {...register("property_condition")}
                                                    className={`form-select form-control ${errors.property_condition ? "is-invalid" : ""
                                                        }`}
                                                    aria-label="Please enter condition"
                                                    defaultValue={"DEFAULT"}
                                                >
                                                    <option value={"DEFAULT"} disabled>
                                                        Please select condition
                                                    </option>
                                                    {CONDITION &&
                                                        CONDITION?.length &&
                                                        CONDITION.map(({ value, label }, index) => (
                                                            <option value={value} key={index}>
                                                                {label}
                                                            </option>
                                                        ))}
                                                </select>
                                                <div className="invalid-feedback">
                                                    {errors.property_condition?.message}
                                                </div>
                                            </div>
                                            <div>
                                                <span className="mt-2 ml-1" id="property_condition">
                                                    <i
                                                        className="fa fa-info-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                                <Tooltip
                                                    placement="top"
                                                    isOpen={tooltipOpen === "property_condition"}
                                                    target="property_condition"
                                                    toggle={() => toggle("property_condition")}
                                                    style={tooltipStyle}
                                                >
                                                    <p className="tooltip-details">
                                                        Poor = Renovations are suggested
                                                    </p>
                                                    <p className="tooltip-details">
                                                        Fair = Ok as-is,but updating suggested
                                                    </p>
                                                    <p className="tooltip-details">
                                                        Good = Clean, Minor updates optional
                                                    </p>
                                                    <p className="tooltip-details">
                                                        Excellent = Like new, or recently renovated
                                                    </p>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="custom-form-group">
                                    <Label for="Commitment Level" sm={3}>
                                        Commitment Level *
                                    </Label>
                                    <Col sm={9}>
                                        <div style={{ width: "100%" }} className="d-flex">
                                            <div style={{ width: "96%" }}>
                                                <select
                                                    name="commitment_level"
                                                    {...register("commitment_level")}
                                                    className={`form-select form-control ${errors.commitment_level ? "is-invalid" : ""
                                                        }`}
                                                    aria-label="Please enter commitment level"
                                                    defaultValue={1000}
                                                >
                                                    <option value="1000" disabled>
                                                        Select commitment level
                                                    </option>
                                                    {MOTIVATION_LEVEL &&
                                                        MOTIVATION_LEVEL?.length &&
                                                        MOTIVATION_LEVEL.map(({ value, label }, index) => (
                                                            <option value={value} key={index}>
                                                                {label}
                                                            </option>
                                                        ))}
                                                </select>
                                                <div className="invalid-feedback">
                                                    {errors.commitment_level?.message}
                                                </div>
                                            </div>

                                            <div style={{ width: "4%" }}>
                                                <span className="mt-2 ml-1" id="commitment_level">
                                                    <i
                                                        className="fa fa-info-circle"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                                <Tooltip
                                                    placement="top"
                                                    isOpen={tooltipOpen === "commitment_level"}
                                                    target="commitment_level"
                                                    toggle={() => toggle("commitment_level")}
                                                    style={{ textAlign: "left" }}
                                                >
                                                    <p className="tooltip-details">
                                                        1 = Very low motivation
                                                    </p>
                                                    <p className="tooltip-details">2 = Low motivation</p>
                                                    <p className="tooltip-details">
                                                        3 = Medium motivation
                                                    </p>
                                                    <p className="tooltip-details">4 = High motivation</p>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Col>
                                </FormGroup>
                            </div>
                            <div className="custom-form-form-group">
                                <FormGroup row className="custom-form-group">
                                    <Label for="Price" sm={3}>
                                        Price
                                    </Label>
                                    <Col sm={9}>
                                        <input
                                            name="price"
                                            type="text"
                                            {...register("price")}
                                            className={`form-control ${errors.price ? "is-invalid" : ""
                                                }`}
                                            placeholder="Please enter price"
                                            onKeyDown={handleKeyDown}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.price?.message}
                                        </div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="custom-form-group">
                                    <Label for="Frontage (ft)" sm={3}>
                                        Frontage (ft)
                                    </Label>
                                    <Col sm={9}>
                                        <div className="d-flex">
                                            <input
                                                name="frontage"
                                                type="text"
                                                {...register("frontage")}
                                                className={`form-control ${errors.frontage ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Please enter frontage"
                                                onKeyDown={handleKeyDown}
                                            />
                                            <span className="mt-2 ml-1" id="frontage">
                                                <i className="fa fa-info-circle" aria-hidden="true"></i>
                                            </span>
                                            <Tooltip
                                                placement="top"
                                                isOpen={tooltipOpen === "frontage"}
                                                target="frontage"
                                                toggle={() => toggle("frontage")}
                                                style={{ textAlign: "left", maxWidth: "70%" }}
                                            >
                                                <p>
                                                    Optional, but recommending if appealing to developers
                                                    and investors
                                                </p>
                                            </Tooltip>
                                        </div>

                                        <div className="invalid-feedback">
                                            {errors.frontage?.message}
                                        </div>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="custom-form-group">
                                    <Label for="Zoning" sm={3}>
                                        Zoning
                                    </Label>
                                    <Col sm={9}>
                                        <div className="d-flex">
                                            <input
                                                name="zoning"
                                                type="text"
                                                {...register("zoning")}
                                                className={`form-control ${errors.zoning ? "is-invalid" : ""
                                                    }`}
                                                placeholder="Please enter zoning"
                                                onKeyDown={handleKeyDown}
                                            />
                                            <span className="mt-2 ml-1" id="zoning">
                                                <i className="fa fa-info-circle" aria-hidden="true"></i>
                                            </span>
                                            <Tooltip
                                                placement="top"
                                                isOpen={tooltipOpen === "zoning"}
                                                target="zoning"
                                                toggle={() => toggle("zoning")}
                                                style={{ textAlign: "left", maxWidth: "70%" }}
                                            >
                                                <p>
                                                    Optional, but recommending if appealing to developers
                                                    and investors
                                                </p>
                                            </Tooltip>
                                        </div>

                                        <div className="invalid-feedback">
                                            {errors.zoning?.message}
                                        </div>
                                    </Col>
                                </FormGroup>
                            </div>
                            <div className="text-right">
                                <Button
                                    color="primary"
                                    type="submit"
                                    size="sm"
                                    outline
                                    disabled={isDisabled}
                                >
                                    <i className="fa fa-save"></i>
                                    {isDisabled ? (
                                        <Spinner size="sm" className="mr-1">
                                            Loading...
                                        </Spinner>
                                    ) : (
                                        ""
                                    )}
                                    {""}
                                    <span>{props?.editProperty?._id ? "Update" : "Submit"}</span>
                                </Button>
                            </div>
                        </Form>
                    ) : (
                        `You are limited to ${propertyLimitCount} property post(s).  If you wish to add a new property, first delete an existing one.`
                    )}
                </>
            )}
            <Modal isOpen={showAddressSuggestionPopup} centered size="md">
                <div className="modal-header">
                    <h1 className="modal-title">Invalid Address</h1>
                    <button
                        type="button"
                        className="close"
                        onClick={hideInvalidAddressModal}
                    >
                        <span>&times;</span>
                    </button>
                </div>
                <ModalBody className="pt-2">
                    You must enter a specific address
                </ModalBody>
            </Modal>
        </>
    );
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_ADDRESS_KEY,
})(AddProperties);
