import React, { useState } from "react";
import { Button, Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import Loader from "components/spinner/spinner";
import { ChangePassword } from "api/settings.api";

export default function Security() {
  const [spinner, setSpinner] = useState(false);

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .min(6, "Current password must be at least 6 characters")
      .required("Current password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&#.,%_-]{6,}$/,
        "Current password must contain at least 6 characters, one uppercase letter, one lowercase letter and one number"
      ),
    password: Yup.string()
      .min(6, "New password must be at least 6 characters")
      .required("New password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&#.,%_-]{6,}$/,
        "New password must contain at least 6 characters, one uppercase letter, one lowercase letter and one number"
      ),
    confirmPassword: Yup.string()
      .min(6, "Confirm password must be at least 6 characters")
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    try {
      setSpinner(true);
      const response = await ChangePassword(data);
      if (response && response.data && response.data.success) {
        toast.success(response.data?.message);
      } else {
        toast.error(response.data.data.message);
      }
      setSpinner(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.errors.length !== 0
          ? error?.response?.data?.errors[0]?.message
          : error.response.data.message
      );
      setSpinner(false);
    }
  };
  return (
    <>
      <Loader isShow={spinner}></Loader>
      <div>
        <h1>Change your password</h1>
        <Form role="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>Current password </label>
            <input
              name="currentPassword"
              type="password"
              {...register("currentPassword")}
              placeholder="Please enter current password"
              className={`form-control ${errors.currentPassword ? "is-invalid" : ""
                }`}
            />
            <div className="invalid-feedback">
              {errors.currentPassword?.message}
            </div>
          </div>
          <div className="form-group">
            <label>New password </label>
            <input
              name="password"
              type="password"
              {...register("password")}
              placeholder="Please enter new password"
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.password?.message}</div>
          </div>
          <div className="form-group">
            <label>Confirm password </label>
            <input
              name="confirmPassword"
              type="password"
              {...register("confirmPassword")}
              placeholder="Please enter confirm password"
              className={`form-control ${errors.confirmPassword ? "is-invalid" : ""
                }`}
            />
            <div className="invalid-feedback">
              {errors.confirmPassword?.message}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <Button color="primary" type="submit" disabled={spinner}>
              {spinner ? (
                <Spinner size="sm" className="mr-1">
                  Loading...
                </Spinner>
              ) : (
                ""
              )}{" "}
              Change password
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}
