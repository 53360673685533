import {
	Table,
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	Row,
	Col,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { GetUser } from "../../../common/helper";
import AddUser from "./addUser";
import {
	GetAllUsers,
	DeleteUser,
	ChangeRole,
	EditUser,
} from "../../../api/admin.api";
import Loader from "components/spinner/spinner";
import { confirmAlert } from "react-confirm-alert";
import FormateDate from "common/dateFormate";
import { DeleteAllUser } from "api/user.api";
import { ImportExcelUser } from "api/user.api";
import { USER_ROLE } from "../../../constants/general.constant";
export default function Users() {
	const currentUser = GetUser();
	const [importModel, setImportModel] = useState(false);
	const [addUser, addUserModel] = useState(false);
	const [users, setUsers] = useState([]);
	const [editUserData, setEditUser] = useState({});
	const [page, setPage] = useState(1);
	const [spinner, setSpinner] = useState(false);
	const [totalUsers, setTotalUsers] = useState(0);
	const [importUserResponse, setImportUserResponse] = useState(null);

	window.addEventListener("scroll", handleScroll);

	useEffect(() => {
		getUsers();
		// return () => setUsers([]);
		// Remove the scroll event listener when the component unmounts
		// return () => {
		//   window.removeEventListener("scroll", handleScroll);
		// };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	function handleScroll() {
		const windowHeight = window.innerHeight;
		const documentHeight = document.documentElement.scrollHeight;
		if (
			windowHeight + document.documentElement.scrollTop + 1 >= documentHeight &&
			users.length < totalUsers &&
			users.length !== totalUsers &&
			!spinner
		) {
			// Load more data when user is close to the bottom
			setPage((prevPage) => prevPage + 1);
		}
	}

	const handleImport = () => {
		// Access the file input and trigger a click
		document.getElementById("fileUpload").click();
	};
	const handleFileChange = async (event) => {
		try {
			const file = event.target.files[0];

			const fileInput = document.getElementById("fileUpload");

			fileInput.value = null;

			if (file) {
				const userData = new FormData();
				userData.append("fileName", file);
				setSpinner(true);
				const response = await ImportExcelUser(userData);
				setSpinner(false);
				if (response && response.data && response.data.success) {
					setImportUserResponse(response.data.data);
					toast.success(response.data.message);
					setImportModel(!importModel);
					setPage(1);
				} else {
					toast.error(response.data?.message);
				}
			}
		} catch (error) {
			setSpinner(false);
			toast.error(
				error?.response?.data?.errors.length !== 0
					? error?.response?.data?.errors[0]?.message
					: error.response.data.message
			);
		}
	};

	// get all users api call
	async function getUsers() {
		try {
			let data = {
				sortColumn: "created_at",
				pageNumber: page === 0 ? 1 : page,
				pageSize: 30,
			};
			setSpinner(true);
			const response = await GetAllUsers(data);
			if (
				response &&
				response.data &&
				response.data.success &&
				response.data.data &&
				response.data.data.users.length
			) {
				let usersTemp = response.data.data.users;
				setUsers((prevData) => [...prevData, ...usersTemp]);
				setTotalUsers(response.data.data.totalUsers);
			} else {
				toast.error(response.data.data.message);
			}
		} catch (error) {
			toast.error(
				error?.response?.data?.errors.length !== 0
					? error?.response?.data?.errors[0]?.message
					: error.response.data.message
			);
		}
		setSpinner(false);
	}

	const addUserForm = () => {
		setUsers([])
		getUsers();
		setEditUser({});
		addUserModel(!addUser);
	};

	const close = (
		<button className="close" onClick={addUserForm} type="button">
			&times;
		</button>
	);
	const closeModel = (event) => {
		if (event) {
			setUsers([]);
			setPage(1);
			addUserForm();
			setEditUser({});
		}
	};

	const updateUser = (index, userData) => {
		userData.index = index;
		userData.edit = true;
		addUserModel(!addUser);
		setEditUser(userData);
	};

	const deleteUser = async (index, id, type) => {
		confirmAlert({
			title: "Are you sure want to delete this user?",
			closeOnClickOutside: true,
			buttons: [
				{
					label: "Yes",
					onClick: async () => {
						try {
							const data = {
								delete_type: type,
								reason: "TEST",
							};
							setSpinner(true);
							const response = await DeleteUser(data, id);
							if (
								response.data &&
								response.data.data &&
								response.data.success
							) {
								toast.success(response.data.message);

								if (type === "HARD_DELETE") {
									users.splice(index, 1);
								} else {
									const updatedArray = users.map((element, i) => {
										if (index === i) {
											element.status = "DELETED";
										}
										return element;
									});
									setUsers(updatedArray);
									setPage(1);
								}
							} else {
								toast.error(response.data.message);
							}
						} catch (error) {
							toast.error(error.response.data.message);
						}
						setSpinner(false);
					},
					className: "btn btn-primary",
				},
				{
					label: "No",
					onClick: () => { },
					className: "btn btn-danger",
				},
			],
		});
	};

	const changeRole = async (index, id, type) => {
		try {
			confirmAlert({
				title:
					type === USER_ROLE.USER
						? "Are you sure you want to revoke the admin permission?"
						: `Are you sure you want to make this user an ${USER_ROLE.SUPER_ADMIN === type ? "super admin" : "admin"
						}?`,
				closeOnClickOutside: true,
				buttons: [
					{
						label: "Yes",
						onClick: async () => {
							try {
								setSpinner(true);
								const response = await ChangeRole({ role: type }, id);
								if (
									response.data &&
									response.data.data &&
									response.data.success
								) {
									toast.success(response.data.message);
									const updatedArray = users.map((element, i) => {
										if (index === i) {
											element.role = type;
										}
										return element;
									});
									setUsers(updatedArray);
								} else {
									toast.error(
										response.data?.errors[0]?.message ?? response.data.message
									);
								}
							} catch (error) {
								toast.error(
									error?.response.data?.errors[0]?.message ??
									error.response.data.message
								);
							}
							setSpinner(false);
						},
						className: "btn btn-primary",
					},
					{
						label: "No",
						onClick: () => { },
						className: "btn btn-danger",
					},
				],
			});
		} catch (error) {
			toast.error(error.message);
		}
	};

	const suspendUser = async (index, id, type) => {
		try {
			confirmAlert({
				title: `Are you sure you want to ${type ? "suspend" : "unsuspend "
					} the user?`,
				closeOnClickOutside: true,
				buttons: [
					{
						label: "Yes",
						onClick: async () => {
							try {
								setSpinner(true);
								const response = await EditUser({ is_suspended: type }, id);
								if (
									response.data &&
									response.data.data &&
									response.data.success
								) {
									toast.success(response.data.message);
									const updatedArray = users.map((element, i) => {
										if (index === i) {
											element.is_suspended = type;
										}
										return element;
									});
									setUsers(updatedArray);
								} else {
									toast.error(
										response.data?.errors[0]?.message ?? response.data.message
									);
								}
							} catch (error) {
								toast.error(
									error?.response.data?.errors[0]?.message ??
									error.response.data.message
								);
							}
							setSpinner(false);
						},
						className: "btn btn-primary",
					},
					{
						label: "No",
						onClick: () => { },
						className: "btn btn-danger",
					},
				],
			});
		} catch (error) {
			toast.error(error.message);
		}
	};
	const undeletedUser = async (index, id, type) => {
		try {
			confirmAlert({
				title: `Are you sure you want to ${type} the user?`,
				closeOnClickOutside: true,
				buttons: [
					{
						label: "Yes",
						onClick: async () => {
							try {
								setSpinner(true);
								const response = await EditUser({ status: "ACTIVE" }, id);
								if (
									response.data &&
									response.data.data &&
									response.data.success
								) {
									toast.success(response.data.message);
									const updatedArray = users.map((element, i) => {
										if (index === i) {
											element.status = "ACTIVE";
										}
										return element;
									});
									setUsers(updatedArray);
								} else {
									toast.error(
										response.data?.errors[0]?.message ?? response.data.message
									);
								}
							} catch (error) {
								toast.error(
									error?.response.data?.errors[0]?.message ??
									error.response.data.message
								);
							}
							setSpinner(false);
						},
						className: "btn btn-primary",
					},
					{
						label: "No",
						onClick: () => { },
						className: "btn btn-danger",
					},
				],
			});
		} catch (error) {
			toast.error(error.message);
		}
	};
	const importModelClose = () => {
		setImportModel(!importModel);
	};
	const closeImportModel = (
		<button className="close" onClick={importModelClose} type="button">
			&times;
		</button>
	);
	const deleteAll = () => {
		confirmAlert({
			title: "Are you sure want to delete all users ?",
			closeOnClickOutside: true,
			buttons: [
				{
					label: "Yes",
					onClick: async () => {
						try {

							setSpinner(true);
							const response = await DeleteAllUser();
							if (response?.data?.success
							) {
								toast.success(response.data.message);
								setUsers([])
								getUsers();
							} else {
								toast.error(response.data.message);
							}
						} catch (error) {
							toast.error(error.response.data.message);
						}
						setSpinner(false);
					},
					className: "btn btn-primary",
				},
				{
					label: "No",
					onClick: () => { },
					className: "btn btn-danger",
				},
			],
		});
	};

	return (
		<>
			<Loader isShow={spinner}></Loader>
			<div className="body-header">
				<div className="m-3 pt-3">
					<Row>
						<Col lg="6">
							<div className="d-flex justify-content-between">
								<h1>Users</h1>
							</div>
						</Col>
						<Col lg="6" className="text-right">
							{
								<>
									<Button
										color="danger"
										outline
										size="sm"
										onClick={deleteAll}
									>
										<i className="fa fa-trash"></i> Delete All
									</Button>
								</>
							}
							<>
								<input
									type="file"
									accept=".xls,.xlsx,.csv"
									className="d-none"
									id="fileUpload"
									onChange={handleFileChange}
								/>
								<Button color="info" outline size="sm" onClick={handleImport}>
									<i className="fa fa-upload"></i> Import User
								</Button>
							</>

							<Button
								color="primary"
								outline
								size="sm"
								onClick={addUserForm}
							>
								<i className="fa fa-plus-circle"></i> Add User
							</Button>
						</Col>
					</Row>

					<div className="overflow-scroll">
						<Table className="my-2" bordered hover responsive size="" striped>
							<thead>
								<tr>
									<th>Username</th>
									<th>Create Date</th>
									<th>Last Active</th>
									<th>Email</th>
									<th>Role</th>
									<th>Status</th>
									<th>Suspended</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{users.length ? (
									users.map((item, i) => (
										<tr key={i}>
											<td>{item?.user_name ?? "-"}</td>
											<td>{FormateDate(item.created_at) ?? "-"}</td>
											<td>{FormateDate(item.last_active) ?? "-"}</td>
											<td>{item.email ?? "-"}</td>
											<td>{item.role ?? "-"}</td>
											<td>{item.status ?? "-"}</td>
											<td>{item?.is_suspended ? "Yes" : "No"}</td>
											{item?.role === USER_ROLE.SUPER_ADMIN ? (
												<td></td>
											) : (
												<td>
													<UncontrolledDropdown>
														<DropdownToggle
															className="btn-icon-only"
															role="button"
															size="sm"
															onClick={(e) => e.preventDefault()}
														>
															<i className="fas fa-ellipsis-v" />
														</DropdownToggle>
														<DropdownMenu className="dropdown-menu-arrow" right>
															<DropdownItem onClick={() => updateUser(i, item)}>
																<i className="fas fa-pencil"></i> Edit
															</DropdownItem>
															{currentUser?.role === USER_ROLE.SUPER_ADMIN ? (
																<>
																	{item?.role === USER_ROLE.ADMIN ? (
																		<>
																			{/* <DropdownItem
                                        onClick={() =>
                                          changeRole(i, item.id, USER_ROLE.USER)
                                        }
                                      >
                                        <i
                                          className="fa fa-user"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Revoke Admin
                                      </DropdownItem> */}
																		</>
																	) : (
																		<>
																			{/* <DropdownItem
                                        onClick={() =>
                                          changeRole(
                                            i,
                                            item.id,
                                            USER_ROLE.ADMIN
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-user"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Make Admin
                                      </DropdownItem> */}
																		</>
																	)}

																	{/* <DropdownItem
                                    onClick={() =>
                                      changeRole(
                                        i,
                                        item.id,
                                        USER_ROLE.SUPER_ADMIN
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-user-circle"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Make Super Admin
                                  </DropdownItem> */}
																</>
															) : null}
															{item?.is_suspended ? (
																<>
																	<DropdownItem
																		onClick={() =>
																			suspendUser(i, item.id, false)
																		}
																	>
																		<i
																			className="fa fa-ban"
																			aria-hidden="true"
																		></i>{" "}
																		Unsuspend
																	</DropdownItem>
																</>
															) : (
																<DropdownItem
																	onClick={() => suspendUser(i, item.id, true)}
																>
																	<i
																		className="fa fa-ban"
																		aria-hidden="true"
																	></i>{" "}
																	Suspend
																</DropdownItem>
															)}
															<>
																{item.status === "DELETED" ? (
																	<DropdownItem
																		onClick={() =>
																			undeletedUser(i, item.id, "undelete")
																		}
																	>
																		<i
																			className="fa fa-undo"
																			aria-hidden="true"
																		></i>{" "}
																		Undelete
																	</DropdownItem>
																) : (
																	<DropdownItem
																		onClick={() =>
																			deleteUser(i, item.id, "SOFT_DELETE")
																		}
																	>
																		<i
																			className="fa fa-trash"
																			aria-hidden="true"
																		></i>{" "}
																		Soft Delete
																	</DropdownItem>
																)}
															</>
																{currentUser?.role === USER_ROLE.SUPER_ADMIN && (
																	<DropdownItem
																		onClick={() =>
																			deleteUser(i, item.id, "HARD_DELETE")
																		}
																	>
																		<i
																			className="fa fa-trash"
																			aria-hidden="true"
																		></i>{" "}
																		Hard Delete
																	</DropdownItem>

																)}
														</DropdownMenu>
													</UncontrolledDropdown>
												</td>
											)}
										</tr>
									))
								) : (
									<tr>
										<td colSpan={7} className="text-center">
											No records found!
										</td>
									</tr>
								)}
							</tbody>
						</Table>
					</div>
				</div>
			</div>

			{/* Add user model */}
			<Modal isOpen={addUser} centered>
				<ModalHeader close={close}>
					<p className="h1">
						{" "}
						<span>
							{editUserData && editUserData?.edit ? "Edit" : "Add"}
						</span>{" "}
						User
					</p>
				</ModalHeader>
				<ModalBody className="pt-0">
					<AddUser
						close={(e) => closeModel(e)}
						editUser={editUserData}
					></AddUser>
				</ModalBody>
			</Modal>
			{/*  */}
			<Modal isOpen={importModel} centered size="md">
				<ModalHeader close={closeImportModel}>
					<p className="h1">User import results</p>
				</ModalHeader>
				<ModalBody className="pt-0">
					<h3>Counts:</h3>
					<div className="ml-6">
						<div>
							{(importUserResponse &&
								importUserResponse?.data.length) ??
								0}{" "}
							records processed
						</div>
						<div>
							{(importUserResponse && importUserResponse?.failed) ?? 0}{" "}
							records failed
						</div>
						<div>
							{(importUserResponse && importUserResponse?.added) ?? 0}{" "}
							records added
						</div>
					</div>

					<div className="text-right">
						<Button color="primary" type="button" onClick={importModelClose}>
							Ok
						</Button>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}
