import { Link } from "react-router-dom";
// reactstrap components
import {
	Form,
	FormGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	InputGroup,
	Nav,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Modal,
	ModalHeader,
	ModalBody,
	Button,
} from "reactstrap";
import React, { useState } from "react";
import Login from "views/auth/login";
import Signup from "views/auth/signup/signup";
import { GetUser } from "../../common/helper";
import { LogOut } from "../../api/auth.api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ADMIN_ACCESS } from "constants/general.constant";
import { ForgotPassword } from "views/auth/forgotPassword";
import { NotYetImplemented } from "common/notYetImplemented";
import { useLocation } from 'react-router-dom';

// import ProfileLogo from "../../assets/img/icons/profile.svg";
const AdminNavbar = (props) => {
	const navigate = useNavigate();

	const currentUser = GetUser();
	const [login, loginModel] = useState(false);
	const [signup, signupModel] = useState(false);
	const [forgotPasswordModel, setForgotPasswordModel] = useState(false);
	const [notYetImplemented, notYetImplementedModel] = useState(false);
	// dropdown toogle
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);

	const toggle = () => {
		setDropdownOpen(!dropdownOpen);
	};
	const adminToggleDropdown = () => {
		setAdminDropdownOpen(!adminDropdownOpen);
	};

	const loginForm = () => {
		loginModel(!login);
	};
	const signupForm = () => {
		signupModel(!signup);
	};
	const forgotPasswordForm = () => {
		setForgotPasswordModel(!forgotPasswordModel);
	};

	const closeLogin = (
		<button className="close" onClick={loginForm} type="button">
			&times;
		</button>
	);

	const closeSignup = (
		<button className="close" onClick={signupForm} type="button">
			&times;
		</button>
	);

	const closeModel = (event, type) => {
		if (type === "logIn" && event) {
			loginForm();
			window.location.reload(false);
		}
		if (type === "signUp" && event) {
			signupForm();
		}
		if (event === "openForgotPassword") {
			loginForm();
			forgotPasswordForm();
		}
		if (type === "closeForgotPassword" && event) {
			forgotPasswordForm();
		}
	};

	const closeForgotPassword = (
		<button className="close" onClick={closeModel} type="button">
			&times;
		</button>
	);

	const logOut = async () => {
		try {
			await LogOut();
		} catch (error) {
			toast.error(error.response.data.message);
		}
		localStorage.removeItem("user");
		navigate("/");
	};
	const NotImplemented = () => {
		notYetImplementedModel(!notYetImplemented);
	};

	const close = (
		<button className="close" onClick={NotImplemented} type="button">
			&times;
		</button>
	);
	const location = useLocation();
	const { pathname } = location;
	const handleClick = () => {
		if (pathname === "/") {
			window.location.reload();
		}
	};
	return (
		<>
			<div
				className="navbar-top navbar-dark position-fixed navbar-expand-lg p-3"
				expand="md"
				id="navbar-main"
			>
				<div className="row px-4">
					<div className="col-3">
						<Link
							className="h4 mb-0 text-white text-uppercase d-lg-inline-block mr-4"
							to="/"
							onClick={handleClick}
						>
							PreMarketBuyers
						</Link>
						<Link to="/howWorks">
							<Button
								color="light"
								outline
								size="md"
								style={{ padding: "0.625rem 1rem" }}
							>
								How it works
							</Button>
						</Link>
					</div>
					<div className="col-6 d-flex justify-content-center d-md-flex ml-lg-auto d-none">
						<Form className="navbar-search navbar-search-dark form-inline">
							<FormGroup className="mb-0">
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="fas fa-search" />
										</InputGroupText>
									</InputGroupAddon>
									<Input placeholder="Search" type="text" />
								</InputGroup>
							</FormGroup>
						</Form>
					</div>
					<div className="col-3 d-flex justify-content-end">
						<Nav className="align-items-center d-md-flex" navbar>
							{currentUser ? (
								<div className="d-flex justify-content-center align-items-center">
									<div className="mr-3 text-white">{currentUser?.user_name}</div>
									<Dropdown
										isOpen={dropdownOpen}
										toggle={toggle}
										onClick={toggle}
										// onMouseEnter={toggle}
										// onMouseLeave={toggle}
										direction="right"
										end="true"
									>
										<DropdownToggle data-toggle="dropdown" tag="span">
											<img
												alt="Profile"
												width={40}
												className="cursor-pointer"
												src={require("../../assets/img/brand/favicon.png")}
											/>
										</DropdownToggle>
										<DropdownMenu>
											<DropdownItem>
												<Link to="/account" className="text-dark">
													<i className="ni ni-settings-gear-65 mx-2" />
													My Settings
												</Link>
											</DropdownItem>
											<DropdownItem>
												<Link to="/properties" className="text-dark">
													<i className="ni ni-world mx-2" /> My Properties
												</Link>
											</DropdownItem>
											<DropdownItem>
												<Link to="/interests" className="text-dark">
													<i className="ni ni-building mx-2" /> My Interests
												</Link>
											</DropdownItem>
											<>
												{ADMIN_ACCESS.includes(currentUser?.role) ? (
													<Dropdown
														isOpen={adminDropdownOpen}
														toggle={adminToggleDropdown}
														direction="right"
														onMouseEnter={adminToggleDropdown}
														onMouseLeave={adminToggleDropdown}
														className="block"
													>
														<DropdownToggle data-toggle="dropdown" tag="div">
															<div className="dropdown-item">
																<i className="ni ni-circle-08 mx-2" />
																Administrator{" "}
																<i
																	className="fa-solid fa-caret-down ml-2"
																	style={{ fontSize: "20px" }}
																></i>
															</div>
														</DropdownToggle>
														<DropdownMenu>
															<DropdownItem>
																<Link to="/users" className="text-dark">
																	<i className="ni ni-single-02 mx-2" /> Users
																</Link>
															</DropdownItem>

															<DropdownItem>
																<Link
																	to="admin/properties"
																	className="text-dark"
																>
																	<i className="ni ni-world mx-2" /> Properties
																</Link>
															</DropdownItem>

															<DropdownItem>
																<Link
																	to="admin/interests"
																	className="text-dark"
																>
																	<i className="ni ni-building mx-2" />{" "}
																	Interests
																</Link>
															</DropdownItem>
															<DropdownItem>
																<Link
																	to="admin/addressTest"
																	className="text-dark"
																>
																	<i className="ni ni-pin-3 mx-2" /> AddressTest
																</Link>
															</DropdownItem>
															<DropdownItem onClick={NotImplemented}>
																<i className="ni ni-single-02 mx-2" /> Logs
															</DropdownItem>
															<DropdownItem>
																<Link to="admin/settings" className="text-dark">
																	<i className="ni ni-settings-gear-65 mx-2" />{" "}
																	Settings
																</Link>
															</DropdownItem>
														</DropdownMenu>
													</Dropdown>
												) : (
													""
												)}
											</>

											<DropdownItem divider />
											<DropdownItem onClick={logOut}>
												<i className="ni ni-key-25 mx-2" />
												Logout
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
									<img
										alt="Profile"
										width={40}
										className="cursor-pointer mx-2"
										src={require("../../assets/img/brand/message.png")}
										onClick={NotImplemented}
									/>
									<img
										alt="Profile"
										width={40}
										className="cursor-pointer"
										src={require("../../assets/img/brand/notification.png")}
										onClick={NotImplemented}
									/>
								</div>
							) : (
								""
							)}
							{!currentUser ? (
								<div className="d-flex">
									<Button variant="outline-light" onClick={loginForm}>
										Login
									</Button>
									<Button variant="secondary" onClick={signupForm}>
										Signup
									</Button>
								</div>
							) : (
								""
							)}
						</Nav>
					</div>
				</div>
			</div>

			{/* Login model */}
			<Modal isOpen={login} centered>
				<ModalHeader close={closeLogin}>
					<p className="h1">Login</p>
				</ModalHeader>
				<ModalBody className="pt-0">
					<Login close={(e) => closeModel(e, "logIn")}></Login>
				</ModalBody>
			</Modal>

			{/* Register model */}
			<Modal isOpen={signup} centered>
				<ModalHeader close={closeSignup}>
					<p className="h1">Signup</p>
				</ModalHeader>
				<ModalBody className="pt-0">
					<Signup close={(e) => closeModel(e, "signUp")}></Signup>
				</ModalBody>
			</Modal>

			{/* forgot password model */}
			<Modal isOpen={forgotPasswordModel} centered>
				<ModalHeader close={closeForgotPassword}>
					<p className="h1">Forgot password</p>
				</ModalHeader>
				<ModalBody className="pt-0">
					<ForgotPassword
						close={(e) => closeModel(e, "closeForgotPassword")}
					></ForgotPassword>
				</ModalBody>
			</Modal>

			{/* Not yet implemented model */}
			{notYetImplemented &&
				<>
					<NotYetImplemented close={NotImplemented}></NotYetImplemented>
				</>
			}
		</>
	);
};

export default AdminNavbar;
