import React, { useEffect } from "react";
import FormateDate from "common/dateFormate";
import { Col, Table } from "reactstrap";
import { MOTIVATION_LEVEL } from "constants/general.constant";
import { BUILDING_TYPE } from "constants/general.constant";
import { OWNERSHIP_TYPE } from "constants/general.constant";
import { LISTING_TYPE } from "constants/general.constant";
import { CONDITION } from "constants/general.constant";
import { LOCATION_TYPES } from "constants/general.constant";
import { STREET_SIDES } from "constants/general.constant";

export default function TableView(props) {
  useEffect(() => { }, []);
  return (
    <>
      <Col lg="12">
        {["ALL_INTERESTS", "MY_INTERESTS", "MATCH_INTERESTS", "PROPERTY_NONE"].includes(props.type) ? (
          <Col lg="12">
            <Table className="p-1" bordered hover responsive size="" striped>
              <thead>
                <tr>
                  <th width="10%">TITLE</th>
                  <th width="10%">COMMITMENT LEVEL</th>
                  <th width="10%">ADDRESS</th>
                  <th width="10%">LOCATION TYPE</th>
                  <th width="10%">FROM</th>
                  <th width="10%">TO</th>
                  <th width="10%">STREET SIDE</th>
                  <th width="10%">CREATE DATE</th>
                </tr>
              </thead>
              <tbody>
                {props && props.properties.filterData.length ? (
                  props.properties.filterData.map((item, i) => (
                    <tr key={i}>
                      <th>{item?.title ?? "-"}</th>
                      <th>{item?.commitment_level ? MOTIVATION_LEVEL.find((level) => level.value === item?.commitment_level)?.label : "-"}</th>
                      <th>{item?.address ?? "-"}</th>
                      <td>{item?.location_type ? LOCATION_TYPES.find((level) => level.value === item?.location_type)?.label : "-"}</td>
                      <td>{item?.from_val ?? "-"}</td>
                      <td>{item?.to_val ?? "-"}</td>
                      <td>{item?.street_side ? STREET_SIDES.find((level) => level.value === item?.street_side)?.label : "-"}</td>
                      <td>{FormateDate(item?.created_at) ?? "-"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={16} className="text-center">
                      No recodes found!
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        ) : (
          /* Property Table */
          <Col lg="12">
            <Table className="p-1" bordered hover responsive size="" striped>
              <thead>
                <tr>
                  <th width="10%">Unit</th>
                  <th width="10%">CONDITION</th>
                  <th width="10%">FRONTAGE</th>
                  <th width="10%">ZONING</th>
                  <th width="10%">Commitment Level</th>
                  <th width="10%">Address</th>
                  <th width="10%">Listing Type</th>
                  <th width="10%">Building Type</th>
                  <th>Ownership Type</th>
                  <th width="10%">Status</th>
                  <th width="10%">Create date</th>
                  <th width="10%">Start date</th>
                  <th width="10%">Price</th>
                </tr>
              </thead>
              <tbody>
                {props && props?.properties?.filterData?.length ? (
                  props?.properties?.filterData?.map((item, i) => (
                    <tr key={i}>
                      <th>{item?.addr_unit ?? "-"}</th>
                      <th>
                        {item?.property_condition ? CONDITION.find((level) => level.value === item?.property_condition).label : "-"}
                      </th>
                      <th>{item?.frontage ?? "-"}</th>
                      <th>{item?.zoning ?? "-"}</th>
                      <th>
                        {item?.commitment_level ? MOTIVATION_LEVEL.find((level) => level.value === item?.commitment_level).label : "-"}
                      </th>
                      <th>{item?.address ?? "-"}</th>
                      <td>
                        {item?.listing_type ? LISTING_TYPE.find((level) => level.value === item?.listing_type)?.label : "-"}
                      </td>
                      <td>
                        {item?.building_type ? BUILDING_TYPE.find((level) => level.value === item?.building_type)?.label : "-"}
                      </td>
                      <td>
                        {item?.ownership_type ? OWNERSHIP_TYPE.find((level) => level.value === item?.ownership_type)?.label : "-"}
                      </td>
                      <td>{item?.status ?? "-"}</td>
                      <td>{FormateDate(item?.created_at) ?? "-"}</td>
                      <td>{FormateDate(item?.start_date) ?? "-"}</td>
                      <td>{item?.price ?? "-"}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={16} className="text-center">
                      No recodes found!
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        )}
      </Col>
    </>
  );
}
