import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { UpdateUser } from "api/settings.api";
import Loader from "components/spinner/spinner";
import Autocomplete from "react-google-autocomplete";
import { handleGoogleAddressProcess } from "common/gecodeAddress";
import { GetUser, SetUser, userCurrentLocation } from "common/helper";
import { MAP_CENTER_TYPE } from "../../../constants/general.constant";
import { removeUserLocation } from "common/helper";
import { GoogleApiWrapper } from "google-maps-react";

function Account() {
  const [spinner, setSpinner] = useState(false);
  const [currentUser, setCurrentUser] = useState(GetUser());
  const [locationType, setLocationType] = useState(
    MAP_CENTER_TYPE.CURRENT_LOCATION
  );
  const [selectedAddress, setSelectedAddress] = useState();

  const validationSchema = Yup.object().shape({
    user_name: Yup.string().required("Username is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    address: Yup.string().optional(),
    latitude: Yup.string().optional(),
    longitude: Yup.string().optional(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("email", currentUser?.email);
    setValue("user_name", currentUser?.user_name);
    setValue("map_center_type", currentUser?.map_center_type);
    if (currentUser?.map_center_type) {
      setLocationType(currentUser?.map_center_type);
    }

    setSelectedAddress(currentUser?.address);
    // setValue("address", currentUser?.address?.address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleGoogleAddress = (place) => {
    handleGoogleAddressProcess(place, async function (googleAddressTemp) {
      setSelectedAddress({
        address: googleAddressTemp?.address,
        latitude: googleAddressTemp?.latitude,
        longitude: googleAddressTemp?.longitude,
      });
      setValue("address", googleAddressTemp?.address);
      setValue("latitude", googleAddressTemp?.latitude);
      setValue("longitude", googleAddressTemp?.longitude);
    });
  };

  const onSubmit = async (data) => {
    try {
      setSpinner(true);
      const response = await UpdateUser(
        {
          email: data.email,
          address: selectedAddress,
          user_name: data.user_name,
          map_center_type: data.map_center_type,
        },
        currentUser?.id
      );

      if (response && response.data && response.data.success) {
        setCurrentUser(response.data?.data?.user);
        SetUser(response.data?.data?.user);
        toast.success(response.data?.message);
      } else {
        toast.error(response.data.data.message);
      }
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      toast.error(
        error?.response?.data?.errors.length !== 0
          ? error?.response?.data?.errors[0]?.message
          : error.response.data.message
      );
    }
  };

  async function setLocation(value) {
    removeUserLocation()
    if (value) {
      const response = await userCurrentLocation();
      if (response.success) {
        setValue("latitude", response.data?.latitude);
        setValue("longitude", response.data?.longitude);
        setValue("map_center_type", MAP_CENTER_TYPE.CURRENT_LOCATION);
        setLocationType(MAP_CENTER_TYPE.CURRENT_LOCATION);
      }
    } else {
      setValue("map_center_type", MAP_CENTER_TYPE.CUSTOM);
      setLocationType(MAP_CENTER_TYPE.CUSTOM);
    }
  }

  return (
    <>
      <Loader isShow={spinner}></Loader>
      <div>
        <h1>Account</h1>
        <Form role="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <label>Email </label>
            <input
              name="email"
              type="text"
              {...register("email")}
              placeholder="Please enter email"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>
          <div className="form-group">
            <label>Username </label>
            <input
              name="user_name"
              type="text"
              {...register("user_name")}
              placeholder="Please enter username"
              className={`form-control ${errors.user_name ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.user_name?.message}</div>
          </div>

          <div className="form-group ">
            <label>Map center</label>
            <div className="custom-control custom-radio">
              <div>
                <input
                  className="custom-control-input"
                  id="customRadio5"
                  name="custom-radio-2"
                  type="radio"
                  checked={locationType === MAP_CENTER_TYPE.CURRENT_LOCATION}
                  onChange={() => {
                    setLocation(true);
                  }}
                />
                <label className="custom-control-label" htmlFor="customRadio5">
                  Center on my current location
                </label>
              </div>

              <div>
                <input
                  className="custom-control-input"
                  checked={locationType === MAP_CENTER_TYPE.CUSTOM}
                  id="customRadio6"
                  name="custom-radio-2"
                  type="radio"
                  onChange={() => {
                    setLocation(false);
                  }}
                />
                <label className="custom-control-label" htmlFor="customRadio6">
                  Center on this address
                </label>
              </div>
            </div>
          </div>
          {locationType === MAP_CENTER_TYPE.CUSTOM ? (
            <div className="form-group">
              <label>Address </label>
              <Autocomplete
                size="sm"
                className={`form-control ${errors.address ? "is-invalid" : ""}`}
                onPlaceSelected={(place) => {
                  handleGoogleAddress(place);
                }}
                options={{
                  types: ["geocode", "establishment"],
                }}
                defaultValue={currentUser?.address?.address}
                {...register("address")}
                placeholder="Please enter address"
              />

              <div className="invalid-feedback">{errors.address?.message}</div>
            </div>
          ) : (
            ""
          )}

          <div className="d-flex justify-content-between">
            <Button color="primary" type="submit" disabled={spinner}>
              {spinner ? (
                <Spinner size="sm" className="mr-1">
                  Loading...
                </Spinner>
              ) : (
                ""
              )}{" "}
              Update
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_ADDRESS_KEY,
})(Account);