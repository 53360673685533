import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import { ToastContainer } from "react-toastify";
import AdminLayout from "layouts/Admin.js";
//import AuthLayout from "layouts/Auth.js";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "index.scss";
import Demo from "components/Demo.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <ToastContainer closeButton={true} position="bottom-left" />
    <Routes>
      <Route path="*" element={<AdminLayout />} />
      {/* <Route path="/auth/*" element={<AuthLayout />} /> */}
      {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
      {/* <Route path="/demo" element={<Demo />} /> */}
    </Routes>
  </BrowserRouter>
);
