const MOTIVATION_LEVEL = [
  { value: 1, label: "Very low motivation" },
  { value: 2, label: "Low motivation" },
  { value: 3, label: "Medium motivation" },
  { value: 4, label: "High motivation" },
];

const CONDITION = [
  { value: "POOR", label: "Poor" },
  { value: "FAIR", label: "Fair" },
  { value: "GOOD", label: "Good" },
  { value: "EXCELLENT", label: "Excellent" },
];

const STATUS = [
  { value: "ACTIVE", label: "Active" },
  { value: "SUSPENDED", label: "Suspended" },
  { value: "WITHDRAWN", label: "Withdrawn" },
  { value: "SOLD", label: "Sold" },
  { value: "RENTED", label: "Rented" },
];

const LISTING_TYPE = [
  { value: "SALE", label: "Sale" },
  { value: "RENT", label: "Rent" },
  { value: "PRECON", label: "Precon" },
];

const LOCATION_TYPES = [
  { value: "EXACT_ADDRESS", label: "Exact address" },
  { value: "STREET", label: "Street" },
  { value: "CITY/TOWN", label: "City/Town" },
  { value: "NEIGHBOURHOOD", label: "Neighbourhood" },
  { value: "SELF_DEFINED_AREA", label: "Self defined area" },
];

const STREET_SIDES = [
  { value: "BOTH_SIDES", label: "Both sides" },
  { value: "EVEN_SIDE_ONLY", label: "Even side only" },
  { value: "ODD_SIDE_ONLY", label: "Odd side only" }
];

const BUILDING_TYPE = [
  { value: "DETACHED", label: "Detached" },
  { value: "SEMI", label: "Semi" },
  { value: "ROW_TOWN", label: "Row town" },
  { value: "STACKED_TOWN", label: "Stacked town" },
  { value: "MULTI", label: "Multi" },
  { value: "LOWRISE", label: "Low rise" },
  { value: "HIGHRISE", label: "High rise" },
  { value: "VACANT_LAND", label: "Vacant land" },
  { value: "PARKING", label: "Parking" },
  { value: "AGRICULTURAL", label: "Agricultural" },
  { value: "OTHER", label: "Other" },
];

const OWNERSHIP_TYPE = [
  { value: "FREEHOLD", label: "Freehold" },
  { value: "CONDO", label: "Condo" },
  { value: "TIMESHARE", label: "Timeshare" },
];

const STREET_DIRECTION = [
  { value: "N", label: "N" },
  { value: "S", label: "S" },
  { value: "E", label: "E" },
  { value: "W", label: "W" },
  { value: "NE", label: "NE" },
  { value: "SE", label: "SE" },
  { value: "SW", label: "SW" },
  { value: "NW", label: "NW" },
];

const ADMIN_ACCESS = ["SUPER_ADMIN", "ADMIN"];


const INTEREST_STREET_COLOR = [
  { value: 1, color: "#aed6f1" },
  { value: 2, color: "#85c1e9" },
  { value: 3, color: "#5dade2" },
  { value: 4, color: "#3498db" },

];

const INTEREST_FILTER = {
  INTERESTS_NONE: "INTERESTS_NONE",
  MY_INTERESTS: "MY_INTERESTS",
  MATCH_INTERESTS: "MATCH_INTERESTS",
  ALL_INTERESTS: "ALL_INTERESTS",
};

const PROPERTY_FILTER = {
  PROPERTY_NONE: "PROPERTY_NONE",
  MY_PROPERTY: "MY_PROPERTY",
  MATCH_PROPERTY: "MATCH_PROPERTY",
  ALL_PROPERTY: "ALL_PROPERTY",
};

const URL_VALIDATION = /^(http|https):\/\/[^\s/$.?#].[^\s]*$/;

const MAP_CENTER_TYPE = {
  CURRENT_LOCATION: "CURRENT_LOCATION",
  CUSTOM: "CUSTOM",
};

const USER_ROLE = {
  USER: "USER",
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
};

module.exports = {
  USER_ROLE,
  MAP_CENTER_TYPE,
  ADMIN_ACCESS,
  STREET_DIRECTION,
  MOTIVATION_LEVEL,
  STATUS,
  LISTING_TYPE,
  OWNERSHIP_TYPE,
  BUILDING_TYPE,
  INTEREST_FILTER,
  PROPERTY_FILTER,
  URL_VALIDATION,
  CONDITION,
  LOCATION_TYPES,
  STREET_SIDES,
  INTEREST_STREET_COLOR
};
