import socketIOClient from "socket.io-client";
import { GetUser } from "./helper";

const currentUser = GetUser();
let socket = socketIOClient(process.env.REACT_APP_SOCKET_ENDPOINT);

const eventBus = {
  on(event, callback) {
    document.addEventListener(event, callback, true);
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback, true);
  },
};
socket.on(`${currentUser?._id}_suspended`, (response) => {
  eventBus.dispatch("suspended", response);
});

export default eventBus;
