
// import { GoogleApiWrapper } from "google-maps-react";
// import React, { useRef, useState } from "react";


// const DEMO = () => {
 
//     return (
//         <div className="App">
//           <h1>Hello CodeSandbox</h1>
//           <h2>Start editing to see some magic happen!</h2>
//         </div>
//       );
// };
import React, { useEffect, useRef } from "react";
import { GoogleApiWrapper, Map } from "google-maps-react";
import MarkerClusterer from "marker-clusterer-plus";

const properties = [
    { lat: 51.505, lng: -0.09, info: "Property 1 info" },
    { lat: 51.515, lng: -0.1, info: "Property 2 info" },
    { lat: 51.525, lng: -0.11, info: "Property 3 info" },
    { lat: 51.506, lng: -0.091, info: "Property 4 info" },
    { lat: 51.507, lng: -0.092, info: "Property 5 info" },
    { lat: 51.508, lng: -0.093, info: "Property 6 info" },
    { lat: 51.509, lng: -0.094, info: "Property 7 info" },
    { lat: 51.510, lng: -0.095, info: "Property 8 info" },
    { lat: 51.511, lng: -0.096, info: "Property 9 info" },
    { lat: 51.512, lng: -0.097, info: "Property 10 info" },
    { lat: 51.513, lng: -0.098, info: "Property 11 info" },
    { lat: 51.514, lng: -0.099, info: "Property 12 info" },
    { lat: 51.516, lng: -0.101, info: "Property 13 info" },
    { lat: 51.517, lng: -0.102, info: "Property 14 info" },
    { lat: 51.518, lng: -0.103, info: "Property 15 info" },
    { lat: 51.519, lng: -0.104, info: "Property 16 info" },
    { lat: 51.520, lng: -0.105, info: "Property 17 info" },
    { lat: 51.521, lng: -0.106, info: "Property 18 info" },
    { lat: 51.522, lng: -0.107, info: "Property 19 info" },
    { lat: 51.523, lng: -0.108, info: "Property 20 info" },
    { lat: 51.524, lng: -0.109, info: "Property 21 info" },
    { lat: 51.526, lng: -0.110, info: "Property 22 info" },
    { lat: 51.527, lng: -0.111, info: "Property 23 info" },
    { lat: 51.528, lng: -0.112, info: "Property 24 info" },
    { lat: 51.529, lng: -0.113, info: "Property 25 info" },
    { lat: 51.530, lng: -0.114, info: "Property 26 info" },
    { lat: 51.531, lng: -0.115, info: "Property 27 info" },
    { lat: 51.532, lng: -0.116, info: "Property 28 info" },
    { lat: 51.533, lng: -0.117, info: "Property 29 info" },
    { lat: 51.534, lng: -0.118, info: "Property 30 info" }, 
];

const MapComponent = (props) => {
  const mapRef = useRef(null);
  const infowindowRef = useRef(new props.google.maps.InfoWindow());

  useEffect(() => {
    const { google } = props;
    console.log(google, "google");
    const map = mapRef.current.map;

    const markers = properties.map((property) => {
      const marker = new google.maps.Marker({
        position: { lat: property.lat, lng: property.lng },
        map: map,
        info: property.info, // Attach property info to the marker
      });

      // Show property info on marker hover
      marker.addListener("mouseover", () => {
        infowindowRef.current.setContent(property.info);
        infowindowRef.current.open(map, marker);
      });

      marker.addListener("mouseout", () => {
        infowindowRef.current.close();
      });

      return marker;
    });

    // Initialize MarkerClusterer after markers are created
    const markerClusterer = new MarkerClusterer(map, markers, {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      averageCenter: true,
    });

    // Handle mouseover event on the clustered marker
    google.maps.event.addListener(markerClusterer, "mouseover", function (c) {
      // Aggregate property info from markers in the cluster
      const clusterInfo = c.getMarkers().map((marker) => marker.info);
      // Create a custom info window without the close button
      const contentString = `<div><ul><li>${clusterInfo.join(
        "<br>"
      )}</li></ul></div>`;
      infowindowRef.current.setContent(contentString);
      infowindowRef.current.setPosition(c.getCenter());
      infowindowRef.current.setOptions({ disableAutoPan: true });
      infowindowRef.current.open(map);
    });

    // Handle mouseout event on the clustered marker
    google.maps.event.addListener(markerClusterer, "mouseout", function (c) {
      infowindowRef.current.close();
    });
  }, [props.google]);

  return (
    <Map
      google={props.google}
      zoom={13}
      initialCenter={{ lat: 51.505, lng: -0.09 }}
      ref={mapRef}
      style={{ height: "100vh", width: "100%" }}
    />
  );
};

const WrappedMapComponent = GoogleApiWrapper({
  apiKey: "AIzaSyAvV7hzGp7oFmlvXNs2C00cA6jxFiIBVUg", // Replace with your API key
})(MapComponent);

const App = () => {
  return (
    <div>
      <WrappedMapComponent />
      {/* {"heelos"} */}
    </div>
  );
};

export default App;