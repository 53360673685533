import React from "react";
import { Spinner } from "reactstrap";

export default function Loader({ isShow }) {
  return (
    <>
      {isShow ? (
        <div
          className="position-fixed d-flex justify-content-center align-items-center w-100 h-100vh top-0 bg-secondary"
          style={{ zIndex: "10", opacity: "0.5" }}
        >
          <Spinner color="primary" size="">
            Loading...
          </Spinner>
        </div>
      ) : null}
    </>
  );
}
