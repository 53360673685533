import axios from "axios";
import { AccessToken, SignOut } from "../common/helper";

const BASE_URL = process.env.REACT_APP_BACKEND_URL;
// create an axios instance
const service = axios.create({
	baseURL: BASE_URL,
});
if (AccessToken()) {
	service.interceptors.request.use(
		(config) => {
			config.headers["Authorization"] = "Bearer " + AccessToken();
			return config;
		},
		(error) => {
			console.error(error);
			return Promise.reject(error);
		}
	);
}

service.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		console.log("error :>> ", error, error.response.status === 401);
		if (error && error.response && error.response.status === 401) {
			console.log("error :>> ", error);
			SignOut();
		}
		console.error(error);
		return Promise.reject(error);
	}
);

export default service;
