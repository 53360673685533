import Loader from "components/spinner/spinner";
import React, { useState, useEffect } from "react";
import { Button, Form, Spinner } from "reactstrap";
import { Row, Col } from "reactstrap";

import { toast } from "react-toastify";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  UpdateSiteSettings,
  GetSiteSettings,
} from "api/admin.api";

export default function AdminSettings() {
  const [spinner, setSpinner] = useState(false);

  const validationSchema = Yup.object().shape({
    emailVerification: Yup.boolean().required("This field is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    getSiteSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSiteSettings = async () => {
    try {
      setSpinner(true);
      const response = await GetSiteSettings();
      setSpinner(false);
      if (response && response.data && response.data.success) {
        const data = response?.data?.data?.siteSettings;
        setValue("emailVerification", data.email_verification);
        setValue("propertyLimit", data.property_limit);
      } else {
        toast.error(response.data?.message);
      }
    } catch (error) {
      setSpinner(false);
      toast.error(
        error?.response?.data?.errors.length !== 0
          ? error?.response?.data?.errors[0]?.message
          : error.response.data.message
      );
    }
  };
  const onSubmit = async (data, event) => {
    event.preventDefault();
    try {
      setSpinner(true);
      const response = await UpdateSiteSettings(data);
      setSpinner(false);
      if (response && response.data && response.data.success) {
        toast.success(response.data?.message);
      } else {
        toast.error(response.data?.message);
      }
    } catch (error) {
      setSpinner(false);
      toast.error(
        error?.response?.data?.errors.length !== 0
          ? error?.response?.data?.errors[0]?.message
          : error.response.data.message
      );
    }
  };
  return (
    <>
      <Loader isShow={spinner}></Loader>
      <div className="body-header">
        <div className="m-3">
          <h1>Admin Settings</h1>

          <Form role="form" className="m-3" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <div className="form-check">
                  <input
                    type="checkbox"
                    {...register("emailVerification")}
                    className="form-check-input"
                  />
                  <label>Require registration email verification </label>

                  <div className="invalid-feedback">
                    {errors.emailVerification?.message}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="2">
                <label>Max properties per user </label>
                <input
                  className="form-control"
                  type="number"
                  {...register("propertyLimit")}
                />

                <div className="invalid-feedback">
                  {errors.propertyLimit?.message}
                </div>
                <div className="mt-2">
                  <Button
                    color="primary"
                    type="submit"
                    size="md"
                    outline
                    disabled={spinner}
                  >
                    {spinner ? (
                      <Spinner size="sm" className="mr-1">
                        Loading...
                      </Spinner>
                    ) : (
                      ""
                    )}
                    Update
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}
