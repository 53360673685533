import request from "./request";

// Add interests
export function CreateInterest(data) {
  return request({
    url: `interests`,
    method: "post",
    data,
  });
}

// get all interests
export function GetAllInterests(data) {
  let params = new URLSearchParams();
  for (let key in data) {
    params.set(key, data[key]);
  }
  return request({
    url: "interests?" + params.toString(),
    method: "get",
    data,
  });
}

// delete Interest
export function DeleteInterest(id) {
  return request({
    url: "interests/" + id,
    method: "delete",
  });
}

// update Interest
export function UpdateInterest(data, id) {
  return request({
    url: "interests/" + id,
    method: "put",
    data,
  });
}

export function GetAllInterestsSuggestion(data) {
  let params = new URLSearchParams();
  for (let key in data) {
    params.set(key, data[key]);
  }
  return request({
    url: "interests/interestResult?" + params.toString(),
    method: "get",
    data,
  });
}
