import { AddressResultPopup } from "common/AddressResultPopup";
import { GoogleApiWrapper } from "google-maps-react";
import React, { useRef, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { Button, Form, Modal, ModalBody } from "reactstrap";

const HeaderTemp = () => {
  const [searchAddress, setSearchAddress] = useState("");
  const [searchPlaceAddress, setSearchPlaceAddress] = useState("");
  const [showAddressResult, setShowAddressResult] = useState(false);
  const [showAddressSuggestionPopup, setShowAddressSuggestionPopup] =
    useState(false);

  const handleInputChange = (event) => {
    setSearchAddress(event.target.value);
  };

  const hideModal = () => {
    setShowAddressResult(!showAddressResult);
    setSearchAddress("");
  };

  const inputRef = useRef(null);

  const hideInvalidAddressModal = () => {
    setShowAddressSuggestionPopup(!showAddressSuggestionPopup);
    setSearchAddress("");

    // Focus on the input field when the component mounts
    inputRef.current.focus();
  };

  return (
    <>
      <div className="text-center">
        <Form>
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              width: "15%",
              left: "40%",
              top: "11px",
            }}
          >
            <Autocomplete
              id="myInput"
              ref={inputRef}
              size="md"
              className="form-control"
              apiKey={process.env.REACT_APP_GOOGLE_ADDRESS_KEY}
              onPlaceSelected={(place) => {
                place = JSON.parse(JSON.stringify(place))

                let streetData = place?.address_components?.find((element) =>
                  element.types.includes("street_number")
                );
                if (!streetData?.long_name) {
                  setShowAddressSuggestionPopup(true);
                  return false;
                } else {
                  setShowAddressResult(true);
                  setSearchAddress(place.formatted_address);
                  setSearchPlaceAddress(place);
                }
              }}
              options={{
                types: ["geocode", "establishment"],
              }}
              placeholder="Address Search"
              onChange={handleInputChange}
              value={searchAddress}
            />
            {searchAddress ? (
              <Button
                size="md"
                color="danger"
                className="adress-search address-close"
                onClick={() => {
                  setSearchAddress("");
                  setSearchPlaceAddress(null);
                }}
              >
                X
              </Button>
            ) : (
              ""
            )}
          </div>
        </Form>
      </div>

      <Modal isOpen={showAddressResult} centered size="lg">
        <ModalBody className="pt-0">
          <AddressResultPopup
            googleAddressResponse={searchPlaceAddress}
            close={hideModal}
            address={searchAddress}
            setSearchPlaceAddress={searchPlaceAddress}
          ></AddressResultPopup>
        </ModalBody>
      </Modal>

      <Modal isOpen={showAddressSuggestionPopup} centered size="md">
        <div className="modal-header">
          <h1 className="modal-title">Invalid Address</h1>
          <button
            type="button"
            className="close"
            onClick={hideInvalidAddressModal}
          >
            <span>&times;</span>
          </button>
        </div>
        <ModalBody className="pt-2">
          You must enter a specific address
        </ModalBody>
      </Modal>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_ADDRESS_KEY,
})(HeaderTemp);
