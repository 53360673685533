import { Button, Modal, ModalHeader, ModalBody, Col, Row, ModalFooter } from "reactstrap";
import React from "react";
import { useEffect, useState } from "react";
import { GetProperty } from "api/properties.api";
import { GetAllInterestsSuggestion } from "api/interest.api";
import AddProperties from "../views/properties/addProperties";
import { NotYetImplemented } from "common/notYetImplemented";
import { PatchDate } from "./helper";
import { MOTIVATION_LEVEL } from "constants/general.constant";
import { BUILDING_TYPE } from "constants/general.constant";
import { OWNERSHIP_TYPE } from "constants/general.constant";
import { LISTING_TYPE } from "constants/general.constant";
import { CONDITION } from "constants/general.constant";
import { formateAddressSlug, googleAddressToPlainObj } from "./addressHelper";
import { STREET_SIDES } from "constants/general.constant";
import { LOCATION_TYPES } from "constants/general.constant";
import InterestTooltip from "./interestTooltip";
import AddInterests from "views/interests/addInterests";

export function AddressResultPopup(props) {
    const [newAddress, setNewAddress] = useState({});
    const [matchingInterests, setMatchingInterests] = useState([]);
    const [notYetImplemented, notYetImplementedModel] = useState(false);
    const [modal, setModal] = useState(false);
    const [sortedProperties, setSortedProperties] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [isOpenAddInterestModal, setIsOpenAddInterestModal] = useState(false);

    const [otherData, setOtherData] = useState({});


    useEffect(() => {
        GetSearchProperty();
        GetAllInterestsPropertySuggestion();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Sort Matching Interest by commitmentLevel in descending order
        const sorted = [...matchingInterests].sort((a, b) => b.commitment_level - a.commitment_level);
        setSortedProperties(sorted);
    }, [matchingInterests]);

    // Function to toggle the modal
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const GetSearchProperty = async () => {

        try {
            const propertySearchData = {
                latitude: parseFloat(props?.setSearchPlaceAddress?.geometry?.location?.lat).toFixed(7),
                longitude: parseFloat(props?.setSearchPlaceAddress?.geometry?.location?.lng).toFixed(7),
            };
            const response = await GetProperty(propertySearchData);
            if (response.data.success) {
                setNewAddress(response?.data?.data?.property);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const GetAllInterestsPropertySuggestion = async () => {
        try {

            const { addressSlug, streetNumber, streetNameShort } = await formateAddressSlug(props?.googleAddressResponse)

            setOtherData({
                address_slug: addressSlug,
                street_number: streetNumber,
                street_name_short: streetNameShort,
            })

            const interestSearchData = {
                address: props?.googleAddressResponse?.formatted_address,
                addressSlug: addressSlug,
            };

            if (streetNameShort) {
                interestSearchData.streetNameShort = streetNameShort;
            }

            if (streetNumber) {
                interestSearchData.streetNumber = streetNumber;
            }

            const response = await GetAllInterestsSuggestion(interestSearchData);
            if (response.data.success) {
                setMatchingInterests(response?.data?.data?.interest);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const NotImplemented = () => {
        notYetImplementedModel(!notYetImplemented);
    };

    const toggle = () => setModal(!modal);

    return (
        <>
            <div className="mt-3 text-center">
                <div className="w-100">
                    <span className="h1 text-center" size="md">
                        Address Results
                    </span>
                    <button className="close" type="button" onClick={props?.close}>
                        ×
                    </button>
                </div>

                {props?.address}
            </div>
            <Row className="row mt-3">
                <Col lg="6">
                    <h3>
                        <u>Property</u>
                    </h3>
                    {Object.keys(newAddress ?? {}).length > 0 ? (
                        <Row>
                            <Col lg="12">
                                <p className="mt-2">
                                    This property is currently posted <br />
                                    nnn Visits
                                </p>

                                <h4>Property Summary:</h4>

                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Building Type:</td>
                                            <td>
                                                {newAddress?.building_type ? BUILDING_TYPE.find((level) => level.value === newAddress?.building_type)?.label : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Ownership Type:</td>
                                            <td>
                                                {newAddress?.ownership_type ? OWNERSHIP_TYPE.find((level) => level.value === newAddress?.ownership_type)?.label : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Listing Type:</td>
                                            <td>
                                                {newAddress?.listing_type ? LISTING_TYPE.find((level) => level.value === newAddress?.listing_type)?.label : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Condition:</td>
                                            <td>
                                                {newAddress?.property_condition ? CONDITION.find((level) => level.value === newAddress?.property_condition)?.label : "-"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Commitment Level: </td>
                                            <td>
                                                {MOTIVATION_LEVEL.find((level) => level.value === newAddress?.commitment_level).label}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col lg="12">
                                <p>This property is not currently posted. </p>
                                <p className="">
                                    If you are the owner, you can post your property by clicking
                                    the button below. There will be no immediate contact with
                                    anyone, or a commitment to sell. You will simply be indicating
                                    that you might be interested in selling, now or at a later
                                    time. And you can remove your property at any time.
                                </p>
                            </Col>
                        </Row>
                    )}
                </Col>

                <Col lg="6">
                    <h3>
                        <u>Interested Buyers</u>
                    </h3>

                    {matchingInterests.length ? (
                        <Row>
                            <Col lg="12">
                                <p className="mt-2">
                                    There {matchingInterests.length > 1 ? "are" : "is"}{" "}
                                    {matchingInterests.length} people interested in this property
                                </p>

                                <h4>Interest Summary:</h4>
                                <ul>
                                    {matchingInterests.filter((num) => num.commitment_level === 4).length > 0 ? (
                                        <li>
                                            {matchingInterests.filter((num) => num.commitment_level === 4).length}{" "} simply have a high interest
                                        </li>
                                    ) : null}

                                    {matchingInterests.filter((num) => num.commitment_level === 3).length > 0 ? (
                                        <li>
                                            {matchingInterests.filter((num) => num.commitment_level === 3).length}{" "} simply have a medium interest
                                        </li>
                                    ) : null}

                                    {matchingInterests.filter((num) => num.commitment_level === 2).length > 0 ? (
                                        <li>
                                            {matchingInterests.filter((num) => num.commitment_level === 2).length}{" "} simply have a low interest
                                        </li>
                                    ) : null}

                                    {matchingInterests.filter((num) => num.commitment_level === 1).length > 0 ? (
                                        <li>
                                            {matchingInterests.filter((num) => num.commitment_level === 1).length}{" "} simply have a passive interest
                                        </li>
                                    ) : null}
                                </ul>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col lg="12">
                                <p>
                                    There are currently no interests saved for this property. If
                                    you are interested in the property, you can save your interest
                                    by clicking the button below. There will be no immediate
                                    contact with anyone, or a commitment with the owner. You will
                                    simply be indicating that you might be interested in this
                                    property, now or at a later time. And you can remove the
                                    interest at any time.
                                </p>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
            <Row>
                <Col lg="6">
                    {Object.keys(newAddress ?? {}).length > 0 ? (
                        <Row>
                            <Col lg="12" className="text-right">
                                <Button
                                    color="primary"
                                    type="button"
                                    size="sm"
                                    outline
                                    className="pull-right"
                                    onClick={NotImplemented}
                                >
                                    Show Post Details
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col lg="12" className="text-right">
                                <Button
                                    onClick={toggle}
                                    color="primary"
                                    type="button"
                                    size="sm"
                                    outline
                                >
                                    Post as my property
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Col>

                <Col lg="6">
                    {matchingInterests.length ? (
                        <Row>
                            <Col lg="12" className="text-right">
                                <Button
                                    color="primary"
                                    type="button"
                                    size="sm"
                                    outline
                                    onClick={toggleModal}
                                >
                                    See Interest List
                                </Button>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col lg="12" className="text-right">
                                <Button
                                    color="primary"
                                    type="button"
                                    size="sm"
                                    outline
                                    style={{ float: "right" }}
                                    onClick={() => {
                                        setIsOpenAddInterestModal(true);
                                    }}
                                >
                                    Save interest of mine
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>

            {notYetImplemented && (
                <>
                    <NotYetImplemented
                        notYetImplemented={notYetImplemented}
                        close={NotImplemented}
                    ></NotYetImplemented>
                </>
            )}
            <Modal isOpen={modal} centered size="lg">
                <ModalHeader toggle={toggle}>
                    <p className="h1">Add Property</p>
                </ModalHeader>

                <ModalBody className="pt-0">
                    <AddProperties
                        close={props?.close}
                        editProperty={{
                            google_address: [props?.googleAddressResponse],
                            edit: true,
                            address: props?.formatted_address,
                            latitude: props?.geometry?.location?.lat,
                            longitude: props?.geometry?.location?.lng,
                            status: "ACTIVE",
                            start_date: PatchDate(new Date()),
                        }}
                    />
                </ModalBody>
            </Modal>


            <Modal isOpen={isOpenAddInterestModal} centered size="lg" toggle={() => setIsOpenAddInterestModal(false)}>
                <ModalHeader>
                    <div style={{ display: "flex" }}>
                        <p className="h1">
                            <span> Add  Interest </span>
                        </p>
                        <span className="mt-2">
                            <InterestTooltip />
                        </span>
                    </div>
                </ModalHeader>
                <ModalBody className="pt-0">
                    <AddInterests
                        close={(e) => {
                            setIsOpenAddInterestModal(false);
                            if (props?.close) {
                                props?.close();
                            }
                        }}
                        editInterest={{
                            google_address: [props?.googleAddressResponse],
                            address: props?.googleAddressResponse?.formatted_address,
                            title: props?.googleAddressResponse?.formatted_address,
                            location_type: "EXACT_ADDRESS",
                            address_slug: otherData?.address_slug,
                            street_name_short: otherData?.street_name_short,
                            street_number: otherData?.street_number,
                            latitude: parseFloat(props?.setSearchPlaceAddress?.geometry?.location?.lat).toFixed(7),
                            longitude: parseFloat(props?.setSearchPlaceAddress?.geometry?.location?.lng).toFixed(7),
                        }}
                    />
                </ModalBody>
            </Modal>
            {/* Properties Interest Popup  */}
            <Modal isOpen={modalOpen} centered size="lg" toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}></ModalHeader>
                <h2 style={{ marginLeft: "20px" }}>Interest List</h2>
                <ModalBody>
                    {sortedProperties && sortedProperties.length > 0 && (
                        <div>
                            <table style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th>User Id</th>
                                        <th>Commitment Level</th>
                                        <th>Location Type</th>
                                        <th>Address Range</th>
                                        <th>Street Side</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedProperties.map((interest, index) => (
                                        <tr key={index}>
                                            <td>{interest.user.email}</td>
                                            <td>{interest.commitment_level ? MOTIVATION_LEVEL.find(level => level.value === interest.commitment_level)?.label : "-"}</td>
                                            <td>{interest.location_type ? LOCATION_TYPES.find(level => level.value === interest.location_type)?.label : "-"}</td>
                                            <td>{(interest.from_val && interest.to_val) ? `Between ${interest.from_val} And ${interest.to_val}` : "-"}</td>
                                            <td>{interest.street_side ? STREET_SIDES.find(level => level.value === interest.street_side)?.label : "-"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={toggleModal}>Close</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
