// reactstrap components
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import React from "react";

export function NotYetImplemented(props) {
  return (
    <>
      <Modal isOpen={true} centered size="md">
        <ModalHeader toggle={props.close}>
          <div className="d-flex justify-content-between align-items-center">
            <p className="h2 mb-0">Not Yet Implemented!</p>

          </div>
        </ModalHeader>
        <ModalBody className="pt-0">
          <div>
            This page/feature is <b>Not Yet Implemented</b>. We are working on it.
            Please stay tuned.
          </div>
          <div className="mt-2">
            <b>Thank you!</b>
          </div>
          <div className="text-right">
            <Button color="primary" type="button" onClick={props.close}>
              Ok
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
