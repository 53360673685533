import request from "./request";

export function filterPropertyAndInterest(data) {
	let params = new URLSearchParams();
	for (let key in data) {
		params.set(key, data[key]);
	}
	return request({
		url: "map-view/property",
		method: "post",
		data,
	});
}
