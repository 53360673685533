import request from "./request";

export function DeleteAllUser() {
  return request({
    url: 'users/deleteAll',
    method: 'delete'
  });
}

export function ImportExcelUser(data) {
  return request({
    url: 'users/import',
    method: 'post',
    data
  });
}
