export function handleGoogleAddressProcess(place, cb) {
    const excludedCategories = ["business", "school", "church"];

    // Check if the place has types and exclude specified categories
    if (place.types && place.types.some((type) => excludedCategories.includes(type))) {
        return;
    }

    let googleAddressTemp = {
        address: place?.formatted_address,
        latitude: place?.latitude,
        place_id: place.place_id,
        longitude: place?.longitude,
    };

    for (let i = 0; i < place.address_components.length; i++) {
        if (place.address_components[i].types.includes("sublocality_level_1")) {
            googleAddressTemp.addrStreetType =
                (googleAddressTemp.address_1 ? googleAddressTemp.address_1 + " " : "") +
                place.address_components[i].long_name;
        }
        if (
            place.address_components[i].types.includes("administrative_area_level_1")
        ) {
            googleAddressTemp.province =
                (googleAddressTemp.address_1 ? googleAddressTemp.address_1 + " " : "") +
                place.address_components[i].long_name;
        }
        if (place.address_components[i].types.includes("route")) {
            googleAddressTemp.addrStreet = place.address_components[i].long_name;
        }

        if (place.address_components[i].types.includes("locality")) {
            googleAddressTemp.city = place.address_components[i].long_name;
        }

        if (place.address_components[i].types.includes("country")) {
            googleAddressTemp.country = place.address_components[i].long_name;
        }
        if (place.address_components[i].types.includes("street_number")) {
            googleAddressTemp.addrNumber = place.address_components[i].long_name;
        }
    }

    googleAddressTemp.latitude = place?.geometry?.location?.lat;
    googleAddressTemp.longitude = place?.geometry?.location?.lng;

    cb(googleAddressTemp);
}
