import React, { useState } from "react";
import { Button, Col, Form, Row, Table } from "reactstrap";
import Autocomplete from "react-google-autocomplete";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Loader from "components/spinner/spinner";
import "../admin.css";
import { GoogleApiWrapper } from "google-maps-react";

function AddressTest() {
  const [isDisabled, setIsDisabled] = useState(false);
  const [address, setAddress] = useState("");
  const [inputAddress, setInputAddress] = useState("");
  const [subpremise, setSubpremise] = useState(null);
  const [streetNumber, setStreetNumber] = useState(null);
  const [route, setRoute] = useState(null);
  const [neighborhood, setNeighborhood] = useState(null);
  const [sub_locality_level_1, setSub_locality_level_1] = useState(null);
  const [locality, setLocality] = useState(null);
  const [administrative_level_3, setAdministrative_level_3] = useState(null);
  const [administrative_level_2, setAdministrative_level_2] = useState(null);
  const [administrative_level_1, setAdministrative_level_1] = useState(null);
  const [country, setCountry] = useState(null);
  const [postal_code, setPostal_code] = useState(null);

  const validationSchema = Yup.object().shape({
    address: Yup.string().required("Address is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = async (data) => {
    try {
    } catch (error) {
      toast.error(
        error?.response?.data?.errors.length !== 0
          ? error?.response?.data?.errors[0]?.message
          : error.response.data.message
      );
    }
    setIsDisabled(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission
    }
  };

  const resetAddress = () => {
    setInputAddress("");
    setAddress("");
    setStreetNumber(null);
    setRoute(null);
    setSubpremise(null);
    setNeighborhood(null);
    setSub_locality_level_1(null);
    setLocality(null);
    setAdministrative_level_3(null);
    setAdministrative_level_2(null);
    setAdministrative_level_1(null);
    setCountry(null);
    setPostal_code(null);
  };

  const handleInputChange = (event) => {
    setInputAddress(event.target.value);
  };

  const appendValue = (place) => {
    if (place && place.address_components?.length) {
      place?.address_components.forEach((obj) => {
        if (obj.types[0] === "street_number") {
          setStreetNumber(obj);
        }
        if (obj.types[0] === "route") {
          setRoute(obj);
        }
        if (obj.types[0] === "subpremise") {
          setSubpremise(obj);
        }
        if (obj.types[0] === "neighborhood") {
          setNeighborhood(obj);
        }
        if (obj.types[0] === "sub_locality_level_1") {
          setSub_locality_level_1(obj);
        }

        if (obj.types[0] === "locality") {
          setLocality(obj);
        }
        if (obj.types[0] === "administrative_area_level_3") {
          setAdministrative_level_3(obj);
        }
        if (obj.types[0] === "administrative_area_level_2") {
          setAdministrative_level_2(obj);
        }
        if (obj.types[0] === "administrative_area_level_1") {
          setAdministrative_level_1(obj);
        }
        if (obj.types[0] === "country") {
          setCountry(obj);
        }
        if (obj.types[0] === "postal_code") {
          setPostal_code(obj);
        }
      });
    }
  };
  return (
    <>
      <Loader isShow={isDisabled}></Loader>
      <div className="body-header p-3">
        <Row>
          <Col xl="3" md="6" sm="12">
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label>Address</label>
                <div className="d-flex">
                  <Autocomplete
                    value={inputAddress}
                    size="sm"
                    className={`form-control ${errors.address ? "is-invalid" : ""
                      }`}
                    onPlaceSelected={(place) => {
                      setInputAddress(place.formatted_address);
                      setAddress(place);
                      appendValue(place);
                    }}
                    options={{
                      types: ["geocode", "establishment"],
                    }}
                    {...register("address")}
                    placeholder="Please enter address"
                    onKeyDown={handleKeyDown}
                    onChange={handleInputChange}
                  />
                  {inputAddress ? (
                    <Button
                      color="danger"
                      size="md"
                      // outline
                      className="ml-2 address-close"
                      onClick={() => resetAddress()}
                    >
                      X
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
                <div className="invalid-feedback">
                  {errors.address?.message}
                </div>
              </div>
            </Form>
          </Col>
        </Row>

        {/* {address && Object.keys(address).length ? (
          <>
            <Table bordered className="mt-3">
              <tbody>
                {address &&
                  Object.keys(address).length &&
                  address.address_components.map((item, i) => (
                    <>
                      <tr>
                        <td width="50%">
                          {" "}
                          <b>{item?.types[0]}_long</b>
                        </td>
                        <td>{item?.long_name}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>{item?.types[0]}_short</b>
                        </td>
                        <td>{item?.short_name}</td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </Table>
          </>
        ) : (
          ""
        )} */}
        {address && Object.keys(address).length ? (
          <>
            <div className="mt-2">
              <p>
                <b>Formatted address :&nbsp;</b> {inputAddress}
              </p>
              <p>
                <b>Latitude :&nbsp;</b> {address.geometry.location.lat()}
                <b className="ml-2">Longitude :&nbsp;</b>{" "}
                {address.geometry.location.lng()}
              </p>
              {/* <p>
                <b>Location type :&nbsp;</b>{" "}
                {address.geometry.location_type ?? "-"}
              </p>
              <p>
                <b>Viewport northeast:&nbsp;</b>{" "}
                <p>
                  <span>
                    Latitude:{" "}
                    {address?.geometry?.viewport?.northeast?.lat ?? "-"}
                  </span>{" "}
                  <span className="ml-2">
                    Longitude:{" "}
                    {address?.geometry?.viewport?.northeast?.lng ?? "-"}
                  </span>{" "}
                </p>
              </p>
              <p>
                <b>Viewport southwest:&nbsp;</b>
                <p>
                  <span>
                    Latitude:{" "}
                    {address?.geometry?.viewport?.southwest?.lat ?? "-"}
                  </span>{" "}
                  <span className="ml-2">
                    Longitude:{" "}
                    {address?.geometry?.viewport?.southwest?.lng ?? "-"}
                  </span>{" "}
                </p>
              </p> */}
            </div>
            <Table bordered className="mt-3">
              <tbody>
                {/* subpremise */}
                <tr>
                  <td width="50%">
                    {" "}
                    <b>subpremise_long</b>
                  </td>
                  <td>{subpremise ? subpremise?.long_name : "-"}</td>
                </tr>
                <tr>
                  <td width="50%">
                    {" "}
                    <b>subpremise_short</b>
                  </td>
                  <td>{subpremise ? subpremise?.short_name : "-"}</td>
                </tr>

                {/* street_number */}
                <tr>
                  <td width="50%">
                    {" "}
                    <b>street_number_long</b>
                  </td>
                  <td>{streetNumber ? streetNumber?.long_name : "-"}</td>
                </tr>
                <tr>
                  <td width="50%">
                    {" "}
                    <b>street_number_short</b>
                  </td>
                  <td>{streetNumber ? streetNumber?.short_name : "-"}</td>
                </tr>

                {/* route */}
                <tr>
                  <td width="50%">
                    {" "}
                    <b>route_long</b>
                  </td>
                  <td>{route ? route?.long_name : "-"}</td>
                </tr>
                <tr>
                  <td width="50%">
                    {" "}
                    <b>route_short</b>
                  </td>
                  <td>{route ? route?.short_name : "-"}</td>
                </tr>

                {/* neighbourhood */}
                <tr>
                  <td width="50%">
                    {" "}
                    <b>neighborhood_long</b>
                  </td>
                  <td>{neighborhood ? neighborhood?.long_name : "-"}</td>
                </tr>
                <tr>
                  <td width="50%">
                    {" "}
                    <b>neighborhood_short</b>
                  </td>
                  <td>{neighborhood ? neighborhood?.short_name : "-"}</td>
                </tr>

                {/* sub_locality_level_1 */}
                <tr>
                  <td width="50%">
                    {" "}
                    <b>sub_locality_level_1_long</b>
                  </td>
                  <td>
                    {sub_locality_level_1
                      ? sub_locality_level_1?.long_name
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    {" "}
                    <b>sub_locality_level_1_short</b>
                  </td>
                  <td>
                    {sub_locality_level_1
                      ? sub_locality_level_1?.short_name
                      : "-"}
                  </td>
                </tr>

                {/* locality */}
                <tr>
                  <td width="50%">
                    {" "}
                    <b>locality_long</b>
                  </td>
                  <td>{locality ? locality?.long_name : "-"}</td>
                </tr>
                <tr>
                  <td width="50%">
                    {" "}
                    <b>locality_short</b>
                  </td>
                  <td>{locality ? locality?.short_name : "-"}</td>
                </tr>

                {/* administrative_level_3 */}
                <tr>
                  <td width="50%">
                    {" "}
                    <b>administrative_level_3_long</b>
                  </td>
                  <td>
                    {administrative_level_3
                      ? administrative_level_3?.long_name
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    {" "}
                    <b>administrative_level_3_short</b>
                  </td>
                  <td>
                    {administrative_level_3
                      ? administrative_level_3?.short_name
                      : "-"}
                  </td>
                </tr>

                {/* administrative_level_2 */}
                <tr>
                  <td width="50%">
                    {" "}
                    <b>administrative_level_2_long</b>
                  </td>
                  <td>
                    {administrative_level_2
                      ? administrative_level_2?.long_name
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    {" "}
                    <b>administrative_level_2_short</b>
                  </td>
                  <td>
                    {administrative_level_2
                      ? administrative_level_2?.short_name
                      : "-"}
                  </td>
                </tr>

                {/* administrative_level_1 */}
                <tr>
                  <td width="50%">
                    {" "}
                    <b>administrative_level_1_long</b>
                  </td>
                  <td>
                    {administrative_level_1
                      ? administrative_level_1?.long_name
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    {" "}
                    <b>administrative_level_1_short</b>
                  </td>
                  <td>
                    {administrative_level_1
                      ? administrative_level_1?.short_name
                      : "-"}
                  </td>
                </tr>

                {/* country */}
                <tr>
                  <td width="50%">
                    {" "}
                    <b>country_long</b>
                  </td>
                  <td>{country ? country?.long_name : "-"}</td>
                </tr>
                <tr>
                  <td width="50%">
                    {" "}
                    <b>country_short</b>
                  </td>
                  <td>{country ? country?.short_name : "-"}</td>
                </tr>

                {/* postal_code */}
                <tr>
                  <td width="50%">
                    {" "}
                    <b>postal_code_long</b>
                  </td>
                  <td>{postal_code ? postal_code?.long_name : "-"}</td>
                </tr>
                <tr>
                  <td width="50%">
                    {" "}
                    <b>postal_code_short</b>
                  </td>
                  <td>{postal_code ? postal_code?.short_name : "-"}</td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_ADDRESS_KEY,
})(AddressTest);