/* eslint-disable jsx-a11y/anchor-has-content */
// reactstrap components
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";

const Footer = () => {
  return (
    <div className="pt-5">
      <div className="position-fixed bottom-0 w-100">
        <footer className="footer p-2 text-white bg-gradient-info">
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="6">
              <div className="copyright text-center text-xl-left">
                © {new Date().getFullYear()}
                {" PreMarketBuyers"}
                <a
                  className="font-weight-bold ml-1"
                  href="https://www.creative-tim.com?ref=adr-admin-footer"
                  rel="noopener noreferrer"
                  target="_blank"
                ></a>
              </div>
            </Col>

            <Col xl="6">
              <Nav className="nav-footer justify-content-end">
                <NavItem>
                  <NavLink href="" rel="noopener noreferrer" target="_blank">
                    About Us
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
