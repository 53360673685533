import React from "react";
import { useLocation, Route, Routes, Navigate } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import routes from "routes.js";
import { Authenticated } from "common/helper";
import eventBus from "common/eventBus";
import { LogOut } from "../api/auth.api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Admin = (props) => {
  const navigate = useNavigate();
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
    eventBus.on("suspended", handleEvent);
    return () => {
      eventBus.remove("suspended", handleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  let handleEvent = async (response) => {
    if (response && response.type === "suspended") {
      try {
        await LogOut();
      } catch (error) {
        toast.error(error.response.data.message);
      }
      localStorage.clear();
      navigate("/");
    }
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.layout === "/user" &&
        (prop.public || (!prop.public && Authenticated()))
      ) {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props?.location?.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      {/* <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/argon-react.png"),
          imgAlt: "...",
        }}
      /> */}
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props?.location?.pathname)}
        />
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        {/* <Container fluid> */}
        <AdminFooter />
        {/* </Container> */}
      </div>
    </>
  );
};

export default Admin;
