import { Button, Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { signIn } from "../../api/auth.api";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { SetUser, SetAuthToken } from "common/helper";

function Login(props) {
  const [isDisabled, setIsDisabled] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    try {
      setIsDisabled(true);
      const response = await signIn({
        email: data.email,
        password: data.password,
      });
      if (response && response.data && response.data.success) {
        SetUser(response.data.data.user);
        SetAuthToken(response?.data?.data?.user.token);
        toast.success(response.data?.message);
        props.close(true);
      } else {
        toast.error(response.data?.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.errors.length !== 0
          ? error?.response?.data?.errors[0]?.message
          : error.response.data.message
      );
    }
    setIsDisabled(false);
  };

  const forgotPassword = () => {
    props.close("openForgotPassword");
  };
  return (
    <>
      <Form role="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Email </label>
          <input
            name="email"
            type="text"
            {...register("email")}
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
            placeholder="Please enter email"
          />
          <div className="invalid-feedback">{errors.email?.message}</div>
        </div>
        <div className="form-group">
          <label>Password </label>
          <input
            name="password"
            type="password"
            {...register("password")}
            placeholder="Please enter password"
            className={`form-control ${errors.password ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.password?.message}</div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <Button color="primary" type="submit" disabled={isDisabled}>
            {isDisabled ? (
              <Spinner size="sm" className="mr-1">
                Loading...
              </Spinner>
            ) : (
              ""
            )}{" "}
            <span>SignIn</span>
          </Button>

          <small className="text-muted" onClick={() => forgotPassword()}>
            Forgot password?
          </small>
        </div>
      </Form>
    </>
  );
}

export default Login;
