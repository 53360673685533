import React, { useEffect, useState } from "react";
import {
    Table,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Col,
    Row,
} from "reactstrap";
import { toast } from "react-toastify";
import Loader from "components/spinner/spinner";
import FormateDate from "common/dateFormate";
import { DeleteProperty, GetAllProperty } from "api/properties.api";
import { confirmAlert } from "react-confirm-alert";
import AddProperties from "./addProperties";
import { PROPERTY_MESSAGES } from "constants/messages.constant";
import { ImportExcel } from "api/admin.api";
import { GetUser } from "common/helper";
import { ADMIN_ACCESS } from "constants/general.constant";
import { useLocation } from "react-router-dom";
import { MOTIVATION_LEVEL } from "constants/general.constant";
import { BUILDING_TYPE } from "constants/general.constant";
import { OWNERSHIP_TYPE } from "constants/general.constant";
import { LISTING_TYPE } from "constants/general.constant";
import { CONDITION } from "constants/general.constant";
import { DeleteAllProperties } from "api/admin.api";

function Properties() {
    const currentUser = GetUser();
    const location = useLocation();
    const currentPath = location.pathname;

    const [addProperties, addPropertiesModel] = useState(false);
    const [properties, setProperties] = useState([]);
    const [editPropertyData, setEditProperty] = useState({});
    const [spinner, setSpinner] = useState(false);
    const [page, setPage] = useState(1);
    const [totalProperties, setTotalProperties] = useState(0);
    const [importPropertyResponse, setImportPropertyResponse] = useState(null);
    const [propertyLimitError] = useState(0);
    const [importModel, setImportModel] = useState(false);

    window.addEventListener("scroll", handleScroll);

    useEffect(() => {
        return () => {
            setPage(1)
            setProperties([])
        };
    },[currentPath])

    useEffect(() => {
        getAllProperties();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);
    
    

    function handleScroll() {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        if (
            windowHeight + document.documentElement.scrollTop + 1 >= documentHeight &&
            properties.length < totalProperties &&
            properties.length !== totalProperties &&
            !spinner
        ) {
            setPage((prevPage) => page + 1);
        }
    }

    const addPropertiesForm = () => {
        addPropertiesModel(!addProperties);
        setEditProperty({});
    };

    const deleteAll = () => {
        confirmAlert({
            title: "Are you sure want to delete all properties ?",
            closeOnClickOutside: true,
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            const data = {};
                            setSpinner(true);
                            const response = await DeleteAllProperties();
                            if (response?.data?.success
                            ) {
                                setProperties([])
                                getAllProperties();
                                toast.success(response.data.message);
                            } else {
                                toast.error(response.data.message);
                            }
                        } catch (error) {
                            toast.error(error.response.data.message);
                        }
                        setSpinner(false);
                    },
                    className: "btn btn-primary",
                },
                {
                    label: "No",
                    onClick: () => { },
                    className: "btn btn-danger",
                },
            ],
        });
    };

    const close = (
        <button className="close" onClick={addPropertiesForm} type="button">
            &times;
        </button>
    );

    const closeModel = (event) => {
        if (event.editProperty) {
            let index = properties.findIndex((obj) => obj._id === event.property._id);
            if (index !== -1) {
                properties[index] = event.property;
            }
        } else {
            properties.unshift(event.property);
        }
        addPropertiesForm();
        setEditProperty({});
    };

    const getAllProperties = async () => {
        try {
            let data = {
                pageSize: 30,
                pageNumber: page === 0 ? 1 : page,
                sortColumn: "created_at",
            };
            if (currentPath === "/properties") {
                data.user_id = currentUser.id;
            } else if (
                ADMIN_ACCESS.includes(currentUser?.role) &&
                currentPath === "/admin/properties"
            ) {
                data.isAdmin = true;
            }
            setSpinner(true);
            const response = await GetAllProperty(data);
            if (
                response &&
                response.data &&
                response.data.success &&
                response.data.data &&
                response.data.data.properties.length
            ) {
                let propertiesTemp = response.data.data.properties;
                setProperties((prevData) => [...prevData, ...propertiesTemp]);
                setTotalProperties(response.data.data.totalProperties);
            } else {
                toast.error(response.data.data.message);
            }
        } catch (error) {
            toast.error(
                error?.response?.data?.errors.length !== 0
                    ? error?.response?.data?.errors[0]?.message
                    : error.response.data.message
            );
        }
        setSpinner(false);
    }

    const deleteProperty = async (index, id) => {
        confirmAlert({
            title: "Are you sure want to delete this property?",
            closeOnClickOutside: true,
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        try {
                            setSpinner(true);
                            const response = await DeleteProperty(id);
                            if (
                                response.data &&
                                response.data.data &&
                                response.data.success
                            ) {
                                // setProperties(
                                //   properties.filter((property) => property.id !== id)
                                // );
                                // setProperties([]);
                                toast.success(PROPERTY_MESSAGES.DELETE);
                                setPage(0);
                                // setProperties([]);
                                setProperties(
                                    properties.filter((property) => property.id !== id)
                                );
                            } else {
                                toast.error(response.data.message);
                            }
                        } catch (error) {
                            toast.error(
                                error?.response?.data?.errors?.length ?
                                    error?.response?.data?.errors[0]?.message :
                                    error.response.data.message
                            );
                        }
                        setSpinner(false);
                    },
                    className: "btn btn-danger",
                },
                {
                    label: "No",
                    onClick: () => { },
                    className: "btn btn-primary",
                },
            ],
        });
    };

    const editProperty = (index, property) => {
        property.index = index;
        property.edit = true;
        addPropertiesModel(!addProperties);
        setEditProperty(property);
    };

    const handleImport = () => {
        // Access the file input and trigger a click
        document.getElementById("fileUpload").click();
    };

    const handleFileChange = async (event) => {
        try {
            const file = event.target.files[0];
            const fileInput = document.getElementById("fileUpload");

            fileInput.value = null;

            if (file) {
                const formData = new FormData();
                formData.append("fileName", file);
                setSpinner(true);
                const response = await ImportExcel(formData);
                setSpinner(false);
                if (response && response.data && response.data.success) {
                    setImportPropertyResponse(response.data.data);
                    toast.success(response.data.message);
                    setImportModel(!importModel);
                    setPage(0);
                } else {
                    toast.error(response.data?.message);
                }
            }
        } catch (error) {
            setSpinner(false);
            toast.error(
                error?.response?.data?.errors.length !== 0
                    ? error?.response?.data?.errors[0]?.message
                    : error.response.data.message
            );
        }
    };

    const importModelClose = () => {
        setImportModel(!importModel);
    };

    const closeImportModel = (
        <button className="close" onClick={importModelClose} type="button">
            &times;
        </button>
    );

    return (
        <>
            <Loader isShow={spinner}></Loader>
            <div className="body-header">
                <div className="m-3 pt-3">
                    <Row>
                        <Col lg="6">
                            <div className="d-flex justify-content-between">
                                <h1>
                                    {ADMIN_ACCESS.includes(currentUser?.role) &&
                                        currentPath === "/admin/properties"
                                        ? null
                                        : "My "}
                                    Properties
                                </h1>
                            </div>
                        </Col>

                        <Col lg="6" className="text-right">
                            {ADMIN_ACCESS.includes(currentUser?.role) &&
                                currentPath === "/admin/properties" ? (
                                <>
                                    <Button
                                        color="danger"
                                        outline
                                        size="sm"
                                        onClick={deleteAll}
                                    >
                                        <i className="fa fa-trash"></i> Delete All
                                    </Button>
                                    <input
                                        type="file"
                                        accept=".xls,.xlsx,.csv"
                                        className="d-none"
                                        id="fileUpload"
                                        onChange={handleFileChange}
                                    />
                                    <Button color="info" outline size="sm" onClick={handleImport}>
                                        <i className="fa fa-upload"></i> Import Property
                                    </Button>
                                </>
                            ) : null}

                            <Button
                                color="primary"
                                outline
                                size="sm"
                                onClick={addPropertiesForm}
                            >
                                <i className="fa fa-plus-circle"></i> Add Property
                            </Button>
                        </Col>
                    </Row>
                    <Table className="my-2" bordered hover responsive size="" striped>
                        <thead>
                            <tr>
                                {ADMIN_ACCESS.includes(currentUser?.role) &&
                                    currentPath === "/admin/properties" ? (
                                    <th>User Id</th>
                                ) : null}
                                <th>Unit</th>
                                <th>Address</th>
                                <th>Building Type</th>
                                <th>Ownership type</th>
                                <th>Listing type</th>
                                <th>Start date</th>
                                <th>Commitment level</th>
                                <th>Status</th>
                                <th>Condition</th>
                                <th>Frontage</th>
                                <th>Zoning</th>
                                <th>Price</th>
                                <th>Create date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {properties.length ? (
                                properties.map((item, i) => (
                                    <tr key={i}>
                                        {ADMIN_ACCESS.includes(currentUser?.role) &&
                                            currentPath === "/admin/properties" ? (
                                            <td>{item?.user?.email}</td>
                                        ) : null}
                                        <th scope="row">{item?.addr_unit ?? "-"}</th>
                                        <td>{item?.address ?? "-"}</td>
                                        <td>
                                            {item?.building_type ? BUILDING_TYPE.find((level) => level.value === item?.building_type)?.label : "-"}
                                        </td>
                                        <td>
                                            {item?.ownership_type ? OWNERSHIP_TYPE.find((level) => level.value === item?.ownership_type)?.label : "-"}
                                        </td>
                                        <td>
                                            {item?.listing_type ? LISTING_TYPE.find((level) => level.value === item?.listing_type)?.label : "-"}
                                        </td>
                                        <td>{FormateDate(item?.start_date) ?? "-"}</td>
                                        <td>
                                            {item?.commitment_level ? MOTIVATION_LEVEL.find((level) => level.value === item?.commitment_level)?.label : "-"}
                                        </td>
                                        <td>{item?.status ?? "-"}</td>
                                        <th>
                                            {item?.property_condition ? CONDITION.find((level) => level.value === item?.property_condition).label : "-"}
                                        </th>
                                        <th>{item?.frontage ?? "-"}</th>
                                        <th>{item?.zoning ?? "-"}</th>
                                        <td>{item?.price ?? "-"}</td>
                                        <td>{FormateDate(item?.created_at) ?? "-"}</td>
                                        <td>
                                            <UncontrolledDropdown>
                                                <DropdownToggle
                                                    className="btn-icon-only"
                                                    role="button"
                                                    size="sm"
                                                    onClick={(e) => e.preventDefault()}
                                                    style={{ width: "1.3rem", height: "1.3rem" }}
                                                >
                                                    <i className="fas fa-ellipsis-v" />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                    <DropdownItem onClick={() => editProperty(i, item)}>
                                                        <i className="fas fa-pencil"></i> Edit
                                                    </DropdownItem>

                                                    <DropdownItem
                                                        onClick={() => deleteProperty(i, item.id)}
                                                    >
                                                        <i className="fas fa-trash"></i> Delete
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={16} className="text-center">
                                        No records found!
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>

            <Modal isOpen={addProperties} centered size="lg">
                <ModalHeader close={close}>
                    <p className="h1">
                        {propertyLimitError && !editPropertyData?.id ? (
                            "Property limit"
                        ) : (
                            <span>{editPropertyData?.id ? "Update" : "Add"} Property</span>
                        )}
                    </p>
                </ModalHeader>
                <ModalBody className="pt-0">
                    <AddProperties
                        close={(e) => closeModel(e)}
                        editProperty={editPropertyData}
                    ></AddProperties>
                </ModalBody>
            </Modal>

            <Modal isOpen={importModel} centered size="md">
                <ModalHeader close={closeImportModel}>
                    <p className="h1">Property import results</p>
                </ModalHeader>
                <ModalBody className="pt-0">
                    <h3>Counts:</h3>
                    <div className="ml-6">
                        <div>
                            {(importPropertyResponse &&
                                importPropertyResponse?.data.length) ??
                                0}{" "}
                            records processed
                        </div>
                        <div>
                            {(importPropertyResponse && importPropertyResponse?.failed) ?? 0}{" "}
                            records failed
                        </div>
                        <div>
                            {(importPropertyResponse && importPropertyResponse?.added) ?? 0}{" "}
                            records added
                        </div>
                        <div>
                            {(importPropertyResponse && importPropertyResponse?.updated) ?? 0}{" "}
                            recodes updated
                        </div>
                    </div>
                    {importPropertyResponse &&
                        importPropertyResponse?.failedRecoded.length ? (
                        <>
                            <h3 className="mt-2">Failed records:</h3>
                            <div className="ml-6">
                                {importPropertyResponse?.failedRecoded.map((element) => (
                                    <div>
                                        Record {element.record}&nbsp;&nbsp; (
                                        {element.unit ? element.unit + " and " : null}
                                        {element.address ? element.address : null})
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        ""
                    )}

                    <div className="text-right">
                        <Button color="primary" type="button" onClick={importModelClose}>
                            Ok
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

export default Properties;
