import request from "./request";

// Add properties
export function CreateProperty(data) {
    return request({
        url: `properties`,
        method: 'post',
        data
    })
}

// get all properties
export function GetAllProperty(data) {
    let params = new URLSearchParams();
    for (let key in data) {
      params.set(key, data[key]);
    }
    return request({
        url: 'properties?' + params.toString(),
        method: 'get',
        data
    })
}

// get all properties
export function GetProperty(data) {
    const queryString = Object.keys(data)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
        .join('&');
    const url = '/properties/get?' + queryString;
    return request({
        url: url,
        method: 'get',
        data
    })
}

// delete properties
export function DeleteProperty(id) { 
    return request({
        url: 'properties/' + id,
        method: 'delete'
    })
}

// update properties
export function UpdateProperty(data, id) {
    return request({
        url: 'properties/' + id,
        method: 'put',
        data
    })
}