import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import {
  INTEREST_FILTER,
  PROPERTY_FILTER,
} from "../../constants/general.constant";
import { GetUser } from "../../common/helper";
import "index.scss";
import { NotYetImplemented } from "common/notYetImplemented";
import AddInterests from "../../views/interests/addInterests";
import AddProperties from "../../views/properties/addProperties";
import HeaderTemp from "views/HeaderTemp";
import InterestTooltip from "common/interestTooltip";
import { PropertyLimit } from "api/admin.api";
function Header(props) {
  const currentUser = GetUser();
  const [dropdownProperties, setDropdownProperties] = useState(false);
  const [dropdownInterests, setDropdownInterests] = useState(false);
  const [viewType, setViewType] = useState("MAP");
  const [notYetImplemented, notYetImplementedModel] = useState(false);
  const [addInterestModal, setaAddInterestModal] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [propertyLimitError, setPropertyLimitError] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(
    PROPERTY_FILTER.ALL_PROPERTY
  );

  const toggleInterests = () => {
    setDropdownInterests(!dropdownInterests);
  };

  const toggleProperties = () => {
    setDropdownProperties(!dropdownProperties);
  };
  useEffect(() => {
    getSiteSettings();
  }, []);

  const setFilterType = (value) => {
    if (PROPERTY_FILTER.PROPERTY_NONE === value) {
      setSelectedFilter(INTEREST_FILTER.ALL_INTERESTS);
    } else if (INTEREST_FILTER.INTERESTS_NONE === value) {
      setSelectedFilter(PROPERTY_FILTER.ALL_PROPERTY);
    } else {
      setSelectedFilter(value);
    }

    props.filter(value);
  };
  const getSiteSettings = async () => {
    try {
      const value = JSON.parse(localStorage.getItem("user"));

      if (value?.id) {
        const test = await PropertyLimit(value.id);

        setPropertyLimitError(test.data.data.propertyLimitError);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addInterestForm = () => {
    setaAddInterestModal(!addInterestModal);
  };

  const setView = () => {
    const type = viewType && viewType === "MAP" ? "TABLE" : "MAP";
    setViewType(type);
    props.type(type);
  };

  const NotImplemented = () => {
    notYetImplementedModel(!notYetImplemented);
  };
  const popertyPopup = () => {
    setToggleModal(!toggleModal);
  };

  const closePopup = (
    <button className="close" onClick={popertyPopup} type="button">
      &times;
    </button>
  );

  const interestCloseButton = (
    <button className="close" onClick={addInterestForm} type="button">
      &times;
    </button>
  );

  const closeModel = (event) => {
    if (event) {
      addInterestForm();
      setaAddInterestModal(false);
    }
  };

  const specialStyle = {
    backgroundColor: "#5e72e4",
    padding: "4px 9px",
    borderRadius: "17px",
    color: "#ffff",
  };

  return (
    <>
      <div className="body-header w-full">
        <div className="px-3 d-flex justify-content-between align-items-center p-2 flex-wrap">
          <div>
            <Dropdown
              isOpen={dropdownInterests}
              toggle={toggleInterests}
              direction="down"
              end="true"
            >
              <DropdownToggle
                data-toggle="dropdown"
                caret
                className={
                  Object.values(INTEREST_FILTER).includes(selectedFilter)
                    ? "propertyDropdown"
                    : ""
                }
              >
                View
                {Object.values(INTEREST_FILTER).includes(selectedFilter)
                  ? "ing"
                  : ""}{" "}
                {selectedFilter === INTEREST_FILTER.ALL_INTERESTS
                  ? "All Interests"
                  : selectedFilter === INTEREST_FILTER.MY_INTERESTS
                    ? "MY Interests"
                    : selectedFilter === INTEREST_FILTER.MATCH_INTERESTS
                      ? "Match My Properties"
                      : "Interests"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => setFilterType(INTEREST_FILTER.INTERESTS_NONE)}
                >
                  <i className="fa-solid fa-ban 3" />
                  None
                </DropdownItem>
                {currentUser ? (
                  <DropdownItem
                    onClick={() => setFilterType(INTEREST_FILTER.MY_INTERESTS)}
                  >
                    <i className="ni ni-world mx-2" /> My Interests
                  </DropdownItem>
                ) : null}
                {currentUser ? (
                  <DropdownItem
                    onClick={() =>
                      setFilterType(INTEREST_FILTER.MATCH_INTERESTS)
                    }
                  >
                    <i className="fa-solid fa-location-dot mx-2"></i> Interests
                    in my Properties
                  </DropdownItem>
                ) : null}
                <DropdownItem
                  onClick={() => setFilterType(INTEREST_FILTER.ALL_INTERESTS)}
                >
                  <i className="fa-solid fa-globe mx-2"></i>
                  All Interests
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              isOpen={dropdownProperties}
              toggle={toggleProperties}
              direction="down"
              end="true"
            >
              <DropdownToggle
                data-toggle="dropdown"
                caret
                className={
                  Object.values(PROPERTY_FILTER).includes(selectedFilter)
                    ? "propertyDropdown"
                    : ""
                }
              >
                View
                {Object.values(PROPERTY_FILTER).includes(selectedFilter)
                  ? "ing"
                  : ""}{" "}
                {selectedFilter === PROPERTY_FILTER.ALL_PROPERTY
                  ? "All Properties"
                  : selectedFilter === PROPERTY_FILTER.MY_PROPERTY
                    ? "MY Properties"
                    : selectedFilter === PROPERTY_FILTER.MATCH_PROPERTY
                      ? "Match My Interests"
                      : "Properties"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => setFilterType(PROPERTY_FILTER.PROPERTY_NONE)}
                >
                  <i className="fa-solid fa-ban mx-2" />
                  None
                </DropdownItem>
                {currentUser ? (
                  <DropdownItem
                    onClick={() => setFilterType(PROPERTY_FILTER.MY_PROPERTY)}
                  >
                    <i className="ni ni-world  mx-2" /> My Properties
                  </DropdownItem>
                ) : null}
                {currentUser ? (
                  <DropdownItem
                    onClick={() =>
                      setFilterType(PROPERTY_FILTER.MATCH_PROPERTY)
                    }
                  >
                    <i className="fa-solid fa-location-dot mx-2"></i>
                    Properties that match my Interests
                  </DropdownItem>
                ) : null}
                <DropdownItem
                  onClick={() => setFilterType(PROPERTY_FILTER.ALL_PROPERTY)}
                >
                  <i className="fa-solid fa-globe mx-2"></i>
                  All Properties
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <HeaderTemp />
          <div className="d-flex">
            <div>
              <Button
                color="primary"
                size="sm"
                outline
                style={{ fontSize: "15px" }}
                onClick={addInterestForm}
              >
                Add an Interest
              </Button>
              <Button
                color="primary"
                size="sm"
                outline
                style={{ fontSize: "15px" }}
                onClick={popertyPopup}
              >
                Add my Property
              </Button>
            </div>
            <div>
              <i
                className="fas fa-bookmark icon mx-3 cursor-pointer"
                style={{ fontSize: "28px" }}
                onClick={NotImplemented}
              />
              <i
                className="fas fa-heart  mr-2  icon cursor-pointer"
                style={{ fontSize: "28px" }}
                onClick={NotImplemented}
              />
            </div>
            <div className="mapViewToggle">
              <span
                onClick={setView}
                className="mr-1"
                style={viewType === "MAP" ? specialStyle : null}
              >
                Map
              </span>

              <span
                onClick={setView}
                style={viewType === "TABLE" ? specialStyle : null}
              >
                Table
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Not yet implemented model */}
      {notYetImplemented && (
        <>
          <NotYetImplemented close={NotImplemented}></NotYetImplemented>
        </>
      )}

      {/* create interest */}
      <Modal isOpen={addInterestModal} centered size="lg">
        <ModalHeader close={interestCloseButton}>
          <div style={{ display: "flex" }}>
            <p className="h2">Add an Interest</p>
            <span className="mt-2">
              <InterestTooltip />
            </span>
          </div>
        </ModalHeader>

        <ModalBody className="pt-0">
          <AddInterests close={(e) => closeModel(e)}></AddInterests>
        </ModalBody>
      </Modal>
      <Modal isOpen={toggleModal} centered size="lg">
        <ModalHeader close={closePopup}>
          {!propertyLimitError ? (
            <p className="h1">
              <span></span>Add Property
            </p>
          ) : (
            <p className="h1">
              <span></span>Property limit
            </p>
          )}
        </ModalHeader>
        <ModalBody className="pt-0">
          <AddProperties close={popertyPopup}></AddProperties>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Header;
