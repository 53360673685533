//Get user
export function GetUser() {
  return JSON.parse(localStorage.getItem("user"));
}

//set user in local storage
export function SetUser(data) {
  if (data) {
    return localStorage.setItem("user", JSON.stringify(data));
  }
}

//Get area location based on lat and long
export function calculateBoundaries(latitude, longitude, radiusInMeters = 1000) {
  const earthRadius = 6378137;
  const latInRadians = (latitude * Math.PI) / 180;
  const dLat = radiusInMeters / earthRadius;
  const dLon = radiusInMeters / (earthRadius * Math.cos(latInRadians));
  const north = latitude + (dLat * 180) / Math.PI;
  const south = latitude - (dLat * 180) / Math.PI;
  const east = longitude + (dLon * 180) / Math.PI;
  const west = longitude - (dLon * 180) / Math.PI;
  return { north, south, east, west };
}

//Get user location
export function GetUserLocation() {
  return JSON.parse(localStorage.getItem("userLocation"));
}

//set user location in local storage
export function SetUserLocation(data) {
  if (data) {
    return localStorage.setItem("userLocation", JSON.stringify(data));
  }
}

export function removeUserLocation() {
  return localStorage.removeItem("userLocation");
}

//set auth token
export function SetAuthToken(data) {
  if (data) {
    return localStorage.setItem("access_token", JSON.stringify(data));
  }
}

//isAuthenticated
export function Authenticated() {
  let userData = JSON.parse(localStorage.getItem("user"));
  if (userData && Object.keys(userData).length) {
    return true;
  } else {
    return false;
  }
}

//get access token
export function AccessToken() {
  const token = JSON.parse(localStorage.getItem("access_token"));
  return token;
}

//Unauthorized user
export function SignOut() {
  localStorage.clear();
  //window.location = "/";
  // let navigate = useNavigate();
  // navigate("/");
  return null;
}

//patch date in input field formate
export function PatchDate(data) {
  const date = new Date(data);
  const formateDate = `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
  return formateDate;
}

//get date in input field formate
export function GetDate(data) {
  const date = new Date(data);
  const formateDate = `${date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
    }-${date.getFullYear()}`;
  return formateDate;
}

//get user current location
export async function userCurrentLocation() {
  let response = {
    success: true,
    message: "Get current location.",
    data: {},
  };
  if ("geolocation" in navigator) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          response.data = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          resolve(response);
        },
        (error) => {
          response.success = false;
          response.message = error;
          reject(response);
        }
      );
    });
  } else {
    response.success = false;
    response.message = "Geolocation is not supported by your browser.";
    return response;
  }
}

//formate address
export function FormateAddress(data) {
  let address = "";
  if (data) {
    if (data.addrNumber) {
      const number = address.concat(`${data.addrNumber}, `);
      address = number;
    }
    if (data.addrStreetDirection) {
      const direction = address.concat(`${data.addrStreetDirection}, `);
      address = direction;
    }
    if (data.city) {
      const city = address.concat(`${data.city}, `);
      address = city;
    }
    if (data.province) {
      const province = address.concat(`${data.province}, `);
      address = province;
    }
    if (data.country) {
      const country = address.concat(`${data.country}`);
      address = country;
    }
  }
  return address;
}
