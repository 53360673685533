import React, { useState } from "react";
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Card,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import Account from "./account/account";
import Security from "./security/security";
import Notification from "./notifications/notification";
import "./settings.scss";

export default function Settings() {
  const [navPills, setNavPills] = useState(1);

  const toggleNavs = (e, state, index) => {
    e.preventDefault();
    setNavPills(index);
  };

  return (
    <>
      <div className="body-header">
        <div className="sidebar">
          <Nav
            className="nav-fill flex-column justify-content-between user-setting"
            id="tabs-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={navPills === 1}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: navPills === 1,
                })}
                onClick={(e) => toggleNavs(e, "navPills", 1)}
                href="#pablo"
                role="tab"
              >
                Account
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={navPills === 2}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: navPills === 2,
                })}
                onClick={(e) => toggleNavs(e, "navPills", 2)}
                href="#pablo"
                role="tab"
              >
                Security
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={navPills === 3}
                className={classnames("mb-sm-3 mb-md-0", {
                  active: navPills === 3,
                })}
                onClick={(e) => toggleNavs(e, "navPills", 3)}
                href="#pablo"
                role="tab"
              >
                Notifications
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="content">
          <Card className="shadow">
            <CardBody>
              <TabContent activeTab={"tabs" + navPills}>
                <TabPane tabId="tabs1">
                  <Account></Account>
                </TabPane>
                <TabPane tabId="tabs2">
                  <Security></Security>
                </TabPane>
                <TabPane tabId="tabs3">
                  <Notification></Notification>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
