import request from "./request";

export function UpdateUser(data, id) {
  return request({
    url: "users/" + id,
    method: "put",
    data,
  });
}

export function ChangePassword(data, id) {
  return request({
    url: "users/change-password",
    method: "post",
    data,
  });
}
