import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from "reactstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import OtpVerify from "./otp-verify";
import { Register, completeRegistration } from "../../../api/auth.api";
import { toast } from "react-toastify";

function Signup(props) {
  const [otpModel, otpVerifyModel] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const closeOtpForm = () => {
    otpVerifyModel(!otpModel);
  };

  const closeOTP = (
    <button className="close" onClick={closeOtpForm} type="button">
      &times;
    </button>
  );

  const validationSchema = Yup.object().shape({
    userName: Yup.string().required("Username is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&#.,%_-]{6,}$/,
        "Password must contain at least 6 characters, one uppercase letter, one lowercase letter and one number"
      ),
    confirmPassword: Yup.string()
      .min(6, "Confirm password must be at least 6 characters")
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
  });

  const emailValidationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  //Register api
  const otpForm = async () => {
    try {
      const userEmail = getValues("email");
      const emailValid = await emailValidationSchema.isValid({
        email: userEmail,
      });
      if (emailValid) {
        setSpinner(true);
        const response = await Register({
          email: userEmail,
        });
        if (response && response.data && response.data.success) {
          if (
            !response.data.data.emailVerification &&
            !response.data.data.emailVerification !== undefined
          ) {
            setIsDisabled(!isDisabled);
          } else {
            toast.success(response.data?.message);
            otpVerifyModel(!otpModel);
          }
        } else {
          toast.error(response.data?.message);
        }
      } else {
        let message = "";
        if (userEmail === "" || userEmail === null) {
          message = "Email is required";
        } else {
          message = "Email is invalid";
        }
        setError("email", {
          message: message,
        });
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.errors.length !== 0
          ? error?.response?.data?.errors[0]?.message
          : error.response.data.message
      );
    }
    setSpinner(false);
  };

  const emailVerification = (event) => {
    if (event) {
      closeOtpForm();
      setIsDisabled(!isDisabled);
    }
  };

  //complete registration
  const onSubmit = async (data) => {
    try {
      const response = await completeRegistration({
        email: data.email,
        user_name: data.userName,
        password: data.password,
        confirmPassword: data.confirmPassword,
      });
      if (response && response.data && response.data.success) {
        toast.success(response.data?.message);
        props.close(true);
      } else {
        toast.error(response.data?.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.errors.length !== 0
          ? error?.response?.data?.errors[0]?.message
          : error.response.data.message
      );
    }
  };

  return (
    <>
      {/* <Col lg="6" md="8"> */}
      <Form role="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label>Email </label>
          <div className="input-group">
            <input
              name="email"
              type="text"
              disabled={!isDisabled}
              {...register("email")}
              placeholder="Please enter email"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
            />
            <div className="input-group-append">
              <Button
                color="success"
                type="button"
                onClick={otpForm}
                disabled={!isDisabled}
              >
                {spinner && isDisabled ? (
                  <Spinner size="sm" className="mr-1">
                    Loading...
                  </Spinner>
                ) : (
                  ""
                )}{" "}
                <span>Verify Email</span>
              </Button>
            </div>
          </div>
          <div className="invalid-feedback">{errors.email?.message}</div>
        </div>
        <div className="form-group">
          <label>Username </label>
          <input
            name="userName"
            type="text"
            disabled={isDisabled}
            {...register("userName")}
            placeholder="Please enter username"
            className={`form-control ${errors.userName ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.userName?.message}</div>
        </div>

        <div className="form-group">
          <label>Password </label>
          <input
            name="password"
            type="password"
            disabled={isDisabled}
            {...register("password")}
            placeholder="Please enter password"
            className={`form-control ${errors.password ? "is-invalid" : ""}`}
          />
          <div className="invalid-feedback">{errors.password?.message}</div>
        </div>
        <div className="form-group">
          <label>Confirm password </label>
          <input
            name="confirmPassword"
            type="password"
            disabled={isDisabled}
            {...register("confirmPassword")}
            placeholder="Please enter confirm password"
            className={`form-control ${errors.confirmPassword ? "is-invalid" : ""
              }`}
          />
          <div className="invalid-feedback">
            {errors.confirmPassword?.message}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <Button color="primary" type="submit" disabled={isDisabled}>
            {spinner && !isDisabled ? (
              <Spinner size="sm" className="mr-1">
                Loading...
              </Spinner>
            ) : (
              ""
            )}{" "}
            Create account
          </Button>
          {/* <a
            className="text-light"
          >
            <small>Login?</small>
          </a> */}
        </div>
      </Form>

      {/* OTP verify model */}
      <Modal isOpen={otpModel}>
        <ModalHeader close={closeOTP}>
          <p className="h1">Email Verification</p>
        </ModalHeader>
        <ModalBody>
          <OtpVerify
            otpVerification={(e) => emailVerification(e)}
            email={getValues("email")}
          ></OtpVerify>
        </ModalBody>
      </Modal>
      {/* </Col> */}
    </>
  );
}

export default Signup;
