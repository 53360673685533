import request from "./request";

export function CreateUser(data) {
  return request({
    url: `users`,
    method: "post",
    data,
  });
}

export function GetAllUsers(data) {
  let params = new URLSearchParams();
  for (let key in data) {
    params.set(key, data[key]);
  }
  return request({
    url: "users?" + params,
    method: "get",
  });
}

export function DeleteUser(data, id) {
  return request({
    url: "users/" + id,
    method: "delete",
    data,
  });
}

export function EditUser(data, id) {
  return request({
    url: "users/" + id,
    method: "put",
    data,
  });
}

// change role
export function ChangeRole(data, id) {
  return request({
    url: "admin/" + id + "/change-role",
    method: "put",
    data,
  });
}

//import excel property

export function ImportExcel(data) {
  return request({
    url: "excel/import",
    method: "post",
    data,
  });
}

export function ImportInterestsExcel(data) {
  return request({
    url: "excel/interestsImport",
    method: "post",
    data,
  });
}

//update site settings
export function UpdateSiteSettings(data) {
  return request({
    url: "admin/site-settings",
    method: "put",
    data,
  });
}

//get site settings
export function GetSiteSettings(data) {
  return request({
    url: "admin/site-settings",
    method: "get",
    data,
  });
}

export function PropertyLimit(userId) {
  return request({
    url: 'admin/validate-property-limit?user_id=' + userId,
    method: 'get'
  });
}

export function DeleteAllProperties() {
  return request({
    url: 'admin/all-properties',
    method: 'delete'
  });
}

export function DeleteAllInterests() {
  return request({
    url: 'admin/all-interests',
    method: 'delete'
  });
}