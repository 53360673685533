export const googleAddressToPlainObj = async (googleAddressResponse) => {

    let country;
    let administrativeAreaLevel1;
    let administrativeAreaLevel2;
    let administrativeAreaLevel3;
    let locality;
    let streetNumber;
    let streetNameShort;

    if (googleAddressResponse?.address_components) {
        for (let index = 0; index < googleAddressResponse.address_components.length; index++) {
            const types = googleAddressResponse.address_components[index].types;

            if (types && types.includes("country")) {
                if (googleAddressResponse.address_components[index].long_name !== "") {
                    country = googleAddressResponse.address_components[index].long_name;
                }
            }

            if (types && types.includes("administrative_area_level_1")) {
                if (googleAddressResponse.address_components[index].long_name !== "") {
                    administrativeAreaLevel1 = googleAddressResponse.address_components[index].long_name;
                }
            }

            if (types && types.includes("administrative_area_level_2")) {
                if (googleAddressResponse.address_components[index].long_name !== "") {
                    administrativeAreaLevel2 = googleAddressResponse.address_components[index].long_name;
                }
            }

            if (types && types.includes("administrative_area_level_3")) {
                if (googleAddressResponse.address_components[index].long_name !== "") {
                    administrativeAreaLevel3 = googleAddressResponse.address_components[index].long_name;
                }
            }

            if (types && types.includes("locality")) {
                if (googleAddressResponse.address_components[index].long_name !== "") {
                    locality = googleAddressResponse.address_components[index].long_name;
                }
            }

            if (types && types.includes("street_number")) {
                if (googleAddressResponse.address_components[index].long_name !== "") {
                    streetNumber = googleAddressResponse.address_components[index].long_name;
                }
            }

            if (types && types.includes("route")) {
                if (googleAddressResponse.address_components[index].short_name !== "") {
                    streetNameShort = googleAddressResponse.address_components[index].short_name;
                }
            }
        }
    }

    return {
        country,
        administrativeAreaLevel1,
        administrativeAreaLevel2,
        administrativeAreaLevel3,
        locality,
        streetNumber,
        streetNameShort,
    }
};

export const formateAddressSlug = async (googleAddress) => {
    let {
        country,
        administrativeAreaLevel1,
        administrativeAreaLevel2,
        administrativeAreaLevel3,
        locality,
        streetNumber,
        streetNameShort,
    } = await googleAddressToPlainObj(googleAddress);

    let addressSlug = `${country ? country + ", " : ""}${administrativeAreaLevel3 ? administrativeAreaLevel3 + ", " : ""
        }${administrativeAreaLevel2 ? administrativeAreaLevel2 + ", " : ""
        }${administrativeAreaLevel1 ? administrativeAreaLevel1 + ", " : ""
        }${locality ? locality : ""}`;

    return { addressSlug, streetNumber, streetNameShort };
};