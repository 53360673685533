import request from "./request";

export function signIn(data) {
  return request({
    url: `auth/login`,
    method: "post",
    data,
  });
}

export function Register(data) {
  return request({
    url: `auth/signup`,
    method: "post",
    data,
  });
}

export function verifyOTP(data) {
  return request({
    url: `auth/verify-otp`,
    method: "put",
    data,
  });
}

export function completeRegistration(data) {
  return request({
    url: `auth/complete-registration`,
    method: "put",
    data,
  });
}

export function LogOut() {
  return request({
    url: `auth/logout`,
    method: "post",
  });
}

export function forgotPassword(data) {
  return request({
    url: `auth/forgot-password`,
    method: "post",
    data,
  });
}
export function resetPassword(token, data) {
  return request({
    url: "/auth/reset-password/" + token,
    method: "put",
    data,
  });
}
