import React from "react";
import Home from "views/Index.js";
import Demo from "views/Demo.js";
import Interests from "views/interests/interests";
import Properties from "views/properties/properties";
import Users from "views/admin/users/users";
import Settings from "views/settings/settings";
import { ResetPassword } from "views/auth/resetPassword";
import { HowItsWork } from "views/pages/howItWorks";
import AdminSettings from "views/admin/settings/settings";
import AddressTest from "views/admin/addressTest/addressTest";



var routes = [
  {
    path: "/",
    component: <Demo />,
    layout: "/user",
    public: true,
  },
  {
    path: "/old",
    component: <Home />,
    layout: "/user",
    public: true,
  },
  {
    path: "/home",
    component: <Home />,
    layout: "/user",
    public: true,
  },
  {
    path: "/properties",
    component: <Properties />,
    layout: "/user",
    public: false,
  },
  {
    path: "/interests",
    component: <Interests />,
    layout: "/user",
    public: false,
  },
  {
    path: "/users",
    component: <Users />,
    layout: "/user",
    public: false,
  },
  {
    path: "/properties",
    component: <Properties />,
    layout: "/user",
    public: false,
  },

  //account settings
  {
    path: "/account",
    component: <Settings />,
    layout: "/user",
    public: false,
  },
  {
    path: "/howWorks",
    component: <HowItsWork />,
    layout: "/user",
    public: true,
  },
  // reset password
  {
    path: "/reset-password/:token",
    component: <ResetPassword />,
    layout: "/user",
    public: true,
  },

  // admin routes
  {
    path: "admin/properties",
    component: <Properties />,
    layout: "/user",
    public: false,
  },
  {
    path: "admin/interests",
    component: <Interests />,
    layout: "/user",
    public: false,
  },
  {
    path: "admin/settings",
    component: <AdminSettings />,
    layout: "/user",
    public: false,
  },
  {
    path: "admin/addressTest",
    component: <AddressTest />,
    layout: "/user",
    public: false,
  },
];
export default routes;
