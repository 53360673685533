import React from "react";

export function HowItsWork() {
  return (
    <>
      <div className="body-header row w-100">
        <div className="col-2"></div>
        <div className="col-8 mt-1">
          <div className="text-center h1">How PremarketBuyers works</div>
          <div className="mt-5" style={{ fontSize: "18px" }}>
            PremarketBuyers is the waiting-list for the real estate market.
            &nbsp;A place where property buyers can let their interests be
            known, and property owners can find them before they list.
          </div>
          <div className="d-flex justify-content-between w-10 mt-3">
            <div className="w-50 mr-4">
              <div className="h2">Interested Buyers</div>
              <div className="mt-2">
                Do you have specific properties, types, or areas that you are
                interested in buying?&nbsp; They might not currently be listed,
                but the owners might be interested in selling, or are already
                planning to sell soon.
              </div>
              <div className="mt-2">
                - Enter the address of a property that interests you, and see if
                the owner is interested in selling. &nbsp;Broaden the search
                area if you wish.
              </div>
              <div className="mt-2">
                - Save your interests, allowing property owners to see that you
                are interested.
              </div>
              <div className="mt-2">
                - Be notified if an owner post a property that matches your
                saved interests.
              </div>
              <div className="mt-2">
                - Have PremarketBuyers send a message to property owners,
                informing them of your interest (if allowed by them).
              </div>
            </div>
            <div className="w-50">
              <div className="h2">Property Owners</div>
              <div className="mt-2">
                Thinking about selling your property but haven't yet
                listed?&nbsp; There may be buyers already interested in buying
                it.&nbsp; You've already listed? Check anyway.
              </div>
              <div className="mt-2">
                - Enter your address and view interested buyers. &nbsp;See them
                ranked by level of interest.
              </div>
              <div className="mt-2">
                - Save your property as being possibly for sale.&nbsp; Even if
                you're not quite ready, you can set a later date.
              </div>
              <div className="mt-2">- Be notified about potential buyers.</div>
              <div className="mt-2">
                - Have PremarketBuyers send a message to interested buyers,
                informing them of your property (if allowed by them).
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
