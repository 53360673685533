import { GetAllInterests, DeleteInterest } from "api/interest.api";
import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Row,
  Col,
} from "reactstrap";
import AddInterests from "views/interests/addInterests";
import { toast } from "react-toastify";
import Loader from "components/spinner/spinner";
import FormateDate from "common/dateFormate";
import InterestTooltip from "common/interestTooltip";
import { confirmAlert } from "react-confirm-alert";
import { INTEREST_MESSAGES } from "constants/messages.constant";
import { ADMIN_ACCESS } from "constants/general.constant";
import { useLocation } from "react-router-dom";
import { GetUser } from "common/helper";
import { ImportInterestsExcel } from "api/admin.api";
import { MOTIVATION_LEVEL } from "constants/general.constant";
import { LOCATION_TYPES } from "constants/general.constant";
import { STREET_SIDES } from "constants/general.constant";
import { DeleteAllInterests } from "api/admin.api";

function Interests() {
  const currentUser = GetUser();
  const location = useLocation();
  const currentPath = location.pathname;
  const [isSetInterestsData, setInterestsData] = useState(false);
  const [addInterests, addInterestsModel] = useState(false);
  const [interests, setInterests] = useState([]);
  const [editInterestData, setEditInterest] = useState({});
  const [spinner, setSpinner] = useState(false);
  const [page, setPage] = useState(1);
  const [totalInterests, setTotalInterests] = useState(0);
  const [importInterstsResponse, setImportInterestsResponse] = useState(null);
  const [importModel, setImportModel] = useState(false);
  window.addEventListener("scroll", handleScroll);
  useEffect(() => {
    return () => {
      setPage(0)
      setInterests([])
    };
  }, [currentPath])

  useEffect(() => {
    getAllInterest();
  }, [page]);
  function handleScroll() {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (
      windowHeight + document.documentElement.scrollTop + 1 >= documentHeight &&
      interests.length < totalInterests &&
      interests.length !== totalInterests &&
      !spinner
    ) {
      // Load more data when user is close to the bottom
      setPage((prevPage) => page + 1);
    }
  }
  const addInterestsForm = () => {
    addInterestsModel(!addInterests);
    setEditInterest({});
  };

  const closeModel = (event) => {
    if (event) {
      setInterests([]);
      addInterestsForm();
      setPage(0);
    }
    setEditInterest({});
  };
  const close = (
    <button className="close" onClick={addInterestsForm} type="button">
      &times;
    </button>
  );


  async function getAllInterest() {
    try {
      let data = {
        pageSize: 30,
        pageNumber: page === 0 ? 1 : page,
        sortColumn: "created_at",
      };

      if (currentPath === "/interests") {
        data.user_id = currentUser.id;
      } else if (
        ADMIN_ACCESS.includes(currentUser?.role) &&
        currentPath === "/admin/interests"
      ) {
        data.isAdmin = true;
      }

      setSpinner(true);
      const response = await GetAllInterests(data);

      if (response.data.data.interests.length) {
        let interestsTemp = response.data.data.interests;
        setInterests((prevData) => [...prevData, ...interestsTemp]);
        setTotalInterests(response.data.data.totalInterests);
      } else {
        toast.error(response.data.data.message);
      }
    } catch (error) {
      toast.error(
        (error?.response?.data?.errors.length &&
          error?.response?.data?.errors[0]?.message) ??
        error.response.data.message
      );
    }
    setSpinner(false);
  }

  const deleteInterest = async (index, id) => {
    confirmAlert({
      title: "Are you sure want to delete this interest?",
      closeOnClickOutside: true,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              setSpinner(true);
              const response = await DeleteInterest(id);
              if (
                response.data &&
                response.data.data &&
                response.data.success
              ) {
                setInterests(
                  interests.filter((interest) => interest.id !== id)
                );
                toast.success(INTEREST_MESSAGES.DELETE);
                setPage(0);
              } else {
                toast.error(response.data.message);
              }
            } catch (error) {
              toast.error(
                error?.response?.data?.errors.length !== 0
                  ? error?.response?.data?.errors[0]?.message
                  : error.response.data.message
              );
            }
            setSpinner(false);
          },
          className: "btn btn-primary",
        },
        {
          label: "No",
          onClick: () => { },
          className: "btn btn-danger",
        },
      ],
    });
  };
  const editInterest = (index, interest) => {
    interest.index = index;
    interest.edit = true;
    addInterestsModel(!addInterests);
    setEditInterest(interest);
  };

  const handleImport = () => {
    // Access the file input and trigger a click
    document.getElementById("fileUpload").click();
  };
  const handleAddInterestsData = (data) => {
    setInterestsData(data);
  };

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      const fileInput = document.getElementById("fileUpload");

      fileInput.value = null;

      if (file) {
        const formData = new FormData();
        formData.append("fileName", file);
        setSpinner(true);
        const response = await ImportInterestsExcel(formData);
        setSpinner(false);
        if (response && response.data && response.data.success) {
          setImportInterestsResponse(response.data.data);
          // localStorage.setItem('importedInterestsData', JSON.stringify(response.data.data));
          // getAllInterest()
          toast.success(response.data.message);
          setImportModel(!importModel);
          setPage(0);
        } else {
          toast.error(response.data?.message);
        }
      }
    } catch (error) {
      setSpinner(false);
      toast.error(
        error?.response?.data?.errors.length !== 0
          ? error?.response?.data?.errors[0]?.message
          : error.response.data.message
      );
    }
  };

  const importModelClose = () => {
    setImportModel(!importModel);
  };
  const deleteAll = () => {
    confirmAlert({
      title: "Are you sure want to delete all interests ?",
      closeOnClickOutside: true,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {

              setSpinner(true);
              const response = await DeleteAllInterests();
              if (response?.data?.success
              ) {
                setInterests([]);
                getAllInterest();
                toast.success(response.data.message);
              } else {
                toast.error(response.data.message);
              }
            } catch (error) {
              toast.error(error.response.data.message);
            }
            setSpinner(false);
          },
          className: "btn btn-primary",
        },
        {
          label: "No",
          onClick: () => { },
          className: "btn btn-danger",
        },
      ],
    });
  };

  const closeImportModel = (
    <button className="close" onClick={importModelClose} type="button">
      &times;
    </button>
  );

  return (
    <>
      <Loader isShow={spinner}></Loader>
      <div className="body-header">
        <div className="m-3 pt-3">
          <Row>
            <Col lg="6">
              <div className="d-flex justify-content-between">
                <h1>
                  {ADMIN_ACCESS.includes(currentUser?.role) &&
                    currentPath === "/admin/interests"
                    ? null
                    : "My "}
                  Interests
                </h1>
              </div>
            </Col>
            <Col lg="6" className="text-right">
              {ADMIN_ACCESS.includes(currentUser?.role) &&
                currentPath === "/admin/interests" ? (
                <>
                  <Button
                    color="danger"
                    outline
                    size="sm"
                    onClick={deleteAll}
                  >
                    <i className="fa fa-trash"></i> Delete All
                  </Button>
                </>
              ) : null}
              {ADMIN_ACCESS.includes(currentUser?.role) &&
                currentPath === "/admin/interests" ? (
                <>
                  <input
                    type="file"
                    accept=".xls,.xlsx,.csv"
                    className="d-none"
                    id="fileUpload"
                    onChange={handleFileChange}
                  />
                  <Button color="info" outline size="sm" onClick={handleImport}>
                    <i className="fa fa-upload"></i> Import Interests
                  </Button>
                </>
              ) : null}

              <Button
                color="primary"
                outline
                size="sm"
                onClick={addInterestsForm}
              >
                <i className="fa fa-plus-circle"></i> Add Interest
              </Button>
            </Col>
          </Row>
          <Table className="my-2" bordered hover responsive size="" striped>
            <thead>
              <tr>
                {ADMIN_ACCESS.includes(currentUser?.role) &&
                  currentPath === "/admin/interests" ? (
                  <th>User Id</th>
                ) : null}
                <th>Title</th>
                <th>CommitmentLevel</th>
                <th>Address</th>
                <th>Location Type</th>
                <th>From</th>
                <th>To</th>
                <th>Street side</th>
                <th>Create date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {interests.length ? (
                interests.map((item, i) => (
                  <tr key={i}>
                    {ADMIN_ACCESS.includes(currentUser?.role) &&
                      currentPath === "/admin/interests" ? (
                      <td>{item?.user?.email}</td>
                    ) : null}
                    <th scope="row">{item?.title ?? "-"}</th>
                    <td>
                      {item?.commitment_level
                        ? MOTIVATION_LEVEL.find(
                          (level) => level.value === item?.commitment_level
                        )?.label
                        : "-"}
                    </td>
                    <td>{item?.address ?? "-"}</td>
                    <td>{item?.location_type ? LOCATION_TYPES.find((level) => level.value === item?.location_type)?.label : "-"}</td>
                    <td>{item?.from_val ?? "-"}</td>
                    <td>{item?.to_val ?? "-"}</td>

                    <td>{item?.street_side ? STREET_SIDES.find((level) => level.value === item?.street_side)?.label : "-"}</td>
                    <td>{FormateDate(item?.created_at) ?? "-"}</td>
                    <td>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only"
                          role="button"
                          size="sm"
                          onClick={(e) => e.preventDefault()}
                          style={{ width: "1.3rem", height: "1.3rem" }}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem onClick={() => editInterest(i, item)}>
                            <i className="fas fa-pencil"></i> Edit
                          </DropdownItem>

                          <DropdownItem
                            onClick={() => deleteInterest(i, item.id)}
                          >
                            <i className="fas fa-trash"></i> Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={16} className="text-center">
                    No recodes found!
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal isOpen={addInterests} centered size="lg">
        <ModalHeader close={close}>

          <div style={{ display: "flex" }}>
            <p className="h1">
              <span>{editInterestData?.edit ? "Update" : "Add"}</span>&nbsp;an
              Interest
            </p>
            <span className="mt-2">
              <InterestTooltip />
            </span>
          </div>
        </ModalHeader>
        <ModalBody className="pt-0">
          <AddInterests
            close={(e) => closeModel(e)}
            editInterest={editInterestData}
            updateAddInterestsData={handleAddInterestsData}
          />
        </ModalBody>
      </Modal>

      {/* Properties imports result model */}
      <Modal isOpen={importModel} centered size="md">
        <ModalHeader close={closeImportModel}>
          <p className="h1">Interests import results</p>
        </ModalHeader>
        <ModalBody className="pt-0">
          <h3>Counts:</h3>
          <div className="ml-6">
            <div>
              {(importInterstsResponse &&
                importInterstsResponse?.data.length) ??
                0}{" "}
              records processed
            </div>
            <div>
              {(importInterstsResponse && importInterstsResponse?.failed) ?? 0}{" "}
              records failed
            </div>
            <div>
              {(importInterstsResponse && importInterstsResponse?.added) ?? 0}{" "}
              records added
            </div>
            <div>
              {(importInterstsResponse && importInterstsResponse?.updated) ?? 0}{" "}
              recodes updated
            </div>
          </div>
          {importInterstsResponse &&
            importInterstsResponse?.failedRecoded.length ? (
            <>
              <h3 className="mt-2">Failed records:</h3>
              <div className="ml-6">
                {importInterstsResponse?.failedRecoded.map((element) => (
                  <div>
                    Record {element.record}&nbsp;&nbsp;
                    {element.address ? element.address : null}
                  </div>
                ))}
              </div>
            </>
          ) : (
            ""
          )}

          <div className="text-right">
            <Button color="primary" type="button" onClick={importModelClose}>
              Ok
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Interests;
