import { useState, useEffect, useRef } from "react";
import { Map, Marker, InfoWindow, Polyline } from "google-maps-react";
import { GetUser } from "common/helper";

import {
    LOCATION_TYPES,
    MAP_CENTER_TYPE,
    MOTIVATION_LEVEL,
    STREET_SIDES,
    INTEREST_FILTER,
    PROPERTY_FILTER,
    INTEREST_STREET_COLOR
} from "../../constants/general.constant";
import {
    userCurrentLocation,
    SetUserLocation,
    GetUserLocation,
} from "common/helper";
import { NotYetImplemented } from "common/notYetImplemented";
import motivationLevel_1 from "../../assets/img/brand/PropLocG1.png";
import motivationLevel_2 from "../../assets/img/brand/PropLocG2.png";
import motivationLevel_3 from "../../assets/img/brand/PropLocG3.png";
import motivationLevel_4 from "../../assets/img/brand/PropLocG4.png";
import interestMotivationLevel_1 from "../../assets/img/brand/interestMotivationLevel_1.png";
import interestMotivationLevel_2 from "../../assets/img/brand/interestMotivationLevel_2.png";
import interestMotivationLevel_3 from "../../assets/img/brand/interestMotivationLevel_3.png";
import interestMotivationLevel_4 from "../../assets/img/brand/interestMotivationLevel_4.png";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import "./propertyView.scss";

import { AddressResultPopup } from "common/AddressResultPopup";
function MapView(props) {
    const [currentUser] = useState(GetUser());
    const [address, setAddress] = useState({});

    const [zoom, setZoom] = useState(GetUserLocation()?.zoom ?? 14);
    const [propertyList, setPropertyList] = useState([]);

    const mapRef = useRef(null);
    const [toggleModal, setToggleModal] = useState(false);
    const [toggleInterestModal, setToggleInterestModal] = useState(false);
    const [interests, setInterests] = useState({});
    const [selectedProperty, setSelectedProperty] = useState({});
    const [showNotImplementedModal, setShowNotImplementedModal] = useState(false);
    const [activeMarkerData, setActiveMarkerData] = useState({
        activeMarker: {},
        selectedPlace: {},
        showingInfoWindow: false,
    });

    useEffect(() => {
        setPropertyList(props?.properties?.filterData);
    }, [props?.properties?.filterData]);

    const handleInterestMarkerClick = (props, marker) => {
        if (props.type === INTEREST_FILTER.MATCH_INTERESTS) {
            setActiveMarkerData({
                selectedPlace: props,
                activeMarker: marker,
                showingInfoWindow: true,
                interests: props.interests,
            });
        } else {
            setInterests(props?.interestRow);
            setToggleInterestModal(true);
        }
    };

    const handlePropertyMarkerClick = (item) => {
        if (props.type === PROPERTY_FILTER.MATCH_PROPERTY) {
            setShowNotImplementedModal(true);
        } else {
            setSelectedProperty(item);
            setToggleModal(true);
        }
    };

    const closeModel = () => {
        setToggleModal(false);
    };

    const closeInterestModel = () => {
        setToggleInterestModal(false);
    };

    useEffect(() => {
        userLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userLocation = () => {
        let latitude_longitude = {};

        if (currentUser?.map_center_type === MAP_CENTER_TYPE.CUSTOM && currentUser?.address?.latitude && currentUser?.address?.longitude) {
            latitude_longitude = {
                lat: parseFloat(currentUser.address.latitude),
                lng: parseFloat(currentUser.address.longitude),
            };
            setAddress(latitude_longitude);
        } else if (
            GetUserLocation() &&
            Object.keys(GetUserLocation()?.address).length
        ) {
            latitude_longitude = GetUserLocation()?.address;
            setAddress(latitude_longitude);
            setZoom(GetUserLocation()?.zoom ?? 14);
        } else {
            userCurrentLocation().then((response) => {
                if (response.success) {
                    latitude_longitude = {
                        lat: response.data?.latitude,
                        lng: response.data?.longitude,
                    };
                    setAddress(latitude_longitude);
                }
            });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            handleZoomChanged();
        }, 500);

        return () => {
            setLocation();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    const propertyMarkerHandler = (motivationLevel) => {
        let icon = {
            scaledSize: new props.google.maps.Size(42, 42),
            // origin: new props.google.maps.Point(0, 0), // origin
            // anchor: new props.google.maps.Point(0, 0),
        };

        if (motivationLevel === MOTIVATION_LEVEL[0].value) {
            icon.url = motivationLevel_1;
        } else if (motivationLevel === MOTIVATION_LEVEL[1].value) {
            icon.url = motivationLevel_2;
        } else if (motivationLevel === MOTIVATION_LEVEL[2].value) {
            icon.url = motivationLevel_3;
        } else if (motivationLevel === MOTIVATION_LEVEL[3].value) {
            icon.url = motivationLevel_4;
        }
        return icon;
    };



    const interestMarkerHandler = (commitmentLevel) => {
        let icon = {
            scaledSize: new props.google.maps.Size(42, 42),
        };

        if (commitmentLevel === MOTIVATION_LEVEL[0].value) {
            icon.url = interestMotivationLevel_1;
        } else if (commitmentLevel === MOTIVATION_LEVEL[1].value) {
            icon.url = interestMotivationLevel_2;
        } else if (commitmentLevel === MOTIVATION_LEVEL[2].value) {
            icon.url = interestMotivationLevel_3;
        } else if (commitmentLevel === MOTIVATION_LEVEL[3].value) {
            icon.url = interestMotivationLevel_4;
        }
        return icon;
    };

    const interestStreetColorHandler = (commitmentLevel) => {
        return INTEREST_STREET_COLOR.find((e) => e.value === commitmentLevel)?.color
    };

    const handleZoomChanged = () => {
        const map = mapRef.current.map;
        const bounds = map?.getBounds();

        if (bounds) {
            const data = {
                latitudeMax: bounds?.getNorthEast()?.lat() ?? address?.lat + 0.01,
                longitudeMax: bounds?.getNorthEast()?.lng() ?? address?.lng + 0.01,
                latitudeMin: bounds?.getSouthWest()?.lat() ?? address?.lat - 0.01,
                longitudeMin: bounds?.getSouthWest()?.lng() ?? address?.lng - 0.01,
            };
            props.reloadData(data);
            setLocation();
            if (mapRef && mapRef.current && mapRef.current.props) {
                setZoom(mapRef.current.props.zoom);
            }
        }
    };

    const setLocation = () => {
        //set in localStorage
        if (mapRef && mapRef?.current && mapRef?.current?.map) {
            const location = {
                address: {
                    lat: mapRef.current?.map.center?.lat(),
                    lng: mapRef.current?.map.center?.lng(),
                },
                zoom: mapRef.current?.map.zoom,
            };
            if (
                location &&
                location?.zoom &&
                Object.keys(location?.address).length &&
                location?.address.lat &&
                location?.address.lng
            ) {
                SetUserLocation(location);
            }
        }
    };

    const setCurrentLocation = () => {
        userCurrentLocation().then((response) => {
            if (response.success) {
                let latitude_longitude = {
                    lat: response.data?.latitude,
                    lng: response.data?.longitude,
                };
                setAddress(latitude_longitude);
                setZoom(GetUserLocation()?.zoom ?? 14);
            }
        });
    };

    const mapStyles = [
        {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
        },
    ];

    const closeInterest = (
        <button className="close" onClick={closeInterestModel} type="button">
            &times;
        </button>
    );

    //  Map box close
    let onClose = () => {
        if (activeMarkerData.showingInfoWindow) {
            setActiveMarkerData({
                showingInfoWindow: false,
                activeMarker: null,
            });
        }
    };

    let onInfoWindowOpen = () => {
        document.getElementById("button-container").onclick = openPopUP;
    };

    //  Map box close
    let openPopUP = () => {
        setShowNotImplementedModal(true);
    };

    return (
        <>
            <div>
                <Button onClick={setCurrentLocation} className="currentLocation">
                    <i className="fa fa-location" />
                </Button>

                <Map
                    style={{ height: "calc(100vh - 180px)", width: "100%" }}
                    onZoomChanged={handleZoomChanged}
                    onDragend={handleZoomChanged}
                    google={props.google}
                    zoom={zoom}
                    // initialCenter={address}
                    center={address}
                    ref={mapRef}
                    styles={mapStyles}
                >

                    {propertyList?.filter((e) => e.snap_to_road_data)?.map((item, index) => {
                        let cord = item?.snap_to_road_data.map((itm) => {
                            return { lat: itm?.location?.latitude, lng: itm?.location?.longitude }
                        })
                        return Object.values(INTEREST_FILTER).includes(props.type) ? (
                            <Polyline
                                key={index}
                                path={cord}
                                options={{
                                    // strokeColor: "#5dade2",
                                    strokeColor: interestStreetColorHandler(item.commitment_level),
                                    strokeOpacity: 0.8,
                                    strokeWeight: 20,
                                }}
                                interestRow={item}
                                interests={item.interest}
                                onClick={handleInterestMarkerClick}
                            />
                        ) : (
                            null
                        );
                    })}
                    <Marker position={address} />
                    {propertyList?.map((item, index) => {
                        return Object.values(INTEREST_FILTER).includes(props.type) ? (
                            <Marker
                                key={index}
                                position={{
                                    lat: item.latitude,
                                    lng: item.longitude,
                                }}
                                title={item.address}
                                type={props.type}
                                interestRow={item}
                                interests={item.interest}
                                onClick={handleInterestMarkerClick}
                                icon={interestMarkerHandler(item.commitment_level)}
                                strokeColor="#0000FF"
                            />
                        ) : (
                            <Marker
                                key={index}
                                position={{
                                    lat: item.latitude,
                                    lng: item.longitude,
                                }}
                                title={item.address}
                                type={props.type}
                                onClick={() => handlePropertyMarkerClick(item)}
                                icon={propertyMarkerHandler(item.commitment_level)}
                                strokeColor="#0000FF"
                            />
                        );
                    })}
                    <InfoWindow
                        marker={activeMarkerData.activeMarker}
                        visible={activeMarkerData.showingInfoWindow}
                        onClose={onClose}
                        onOpen={onInfoWindowOpen}
                    >
                        <div id="button-container">
                            <p className="mt-2">
                                There {activeMarkerData?.interests?.length > 1 ? "are" : "is"}{" "}
                                {activeMarkerData?.interests?.length} people interested in this
                                property
                            </p>
                            <table>
                                <tbody>
                                    {activeMarkerData?.interests?.filter(
                                        (num) => num.commitment_level === 1
                                    ).length > 0 ? (
                                        <>
                                            <tr>
                                                <td>
                                                    <li>
                                                        {
                                                            activeMarkerData?.interests?.filter(
                                                                (num) => num.commitment_level === 1
                                                            ).length
                                                        }{" "}
                                                        simply have a passive interest
                                                    </li>
                                                </td>
                                            </tr>
                                        </>
                                    ) : null}

                                    {activeMarkerData?.interests?.filter(
                                        (num) => num.commitment_level === 2
                                    ).length > 0 ? (
                                        <>
                                            <tr>
                                                <td>
                                                    <li>
                                                        {
                                                            activeMarkerData?.interests?.filter(
                                                                (num) => num.commitment_level === 2
                                                            ).length
                                                        }{" "}
                                                        simply have a low interest
                                                    </li>
                                                </td>
                                            </tr>
                                        </>
                                    ) : null}

                                    {activeMarkerData?.interests?.filter(
                                        (num) => num.commitment_level === 3
                                    ).length > 0 ? (
                                        <>
                                            <tr>
                                                <td>
                                                    <li>
                                                        {
                                                            activeMarkerData?.interests?.filter(
                                                                (num) => num.commitment_level === 3
                                                            ).length
                                                        }{" "}
                                                        simply have a medium interest
                                                    </li>
                                                </td>
                                            </tr>
                                        </>
                                    ) : null}

                                    {activeMarkerData?.interests?.filter(
                                        (num) => num.commitment_level === 4
                                    ).length > 0 ? (
                                        <>
                                            <tr>
                                                <td>
                                                    <li>
                                                        {" "}
                                                        {
                                                            activeMarkerData?.interests?.filter(
                                                                (num) => num.commitment_level === 4
                                                            ).length
                                                        }{" "}
                                                        simply have a high interest
                                                    </li>
                                                </td>
                                            </tr>
                                        </>
                                    ) : null}
                                </tbody>
                            </table>
                        </div>
                    </InfoWindow>
                </Map>
            </div>

            <Modal isOpen={toggleModal} centered size="lg">
                <ModalBody className="pt-0">
                    <AddressResultPopup
                        close={closeModel}
                        readOnly={true}
                        data={selectedProperty}
                        address={selectedProperty?.address}
                        googleAddressResponse={selectedProperty?.google_address?.[0]}
                        setSearchPlaceAddress={selectedProperty?.google_address?.[0]}
                    ></AddressResultPopup>
                </ModalBody>
            </Modal>

            <Modal isOpen={toggleInterestModal} centered size="md">
                <ModalHeader close={closeInterest}>
                    <p className="h1">
                        <span></span>Interest Details
                    </p>
                </ModalHeader>
                <ModalBody className="pt-0">
                    <ul>
                        <li>Title : {interests?.title}</li>
                        <br />
                        <li>
                            Interest Location Type :{" "}
                            {interests?.location_type
                                ? LOCATION_TYPES.find(
                                    (level) =>
                                        level.value === interests?.location_type
                                )?.label
                                : "-"}
                        </li>
                        <br />
                        <li>Address: {interests?.address}</li>
                        <br />
                        <li>
                            Commitment Level:{" "}
                            {interests?.commitment_level
                                ? MOTIVATION_LEVEL.find(
                                    (level) =>
                                        level.value === interests?.commitment_level
                                )?.label
                                : "-"}
                        </li>
                        <br />

                        {interests?.location_type === LOCATION_TYPES[1].value &&
                            (interests?.from_val ||
                                interests?.to_val ||
                                interests?.street_side) && (
                                <>
                                    {interests?.street_side && (
                                        <li>
                                            Street Side:{" "}
                                            {
                                                STREET_SIDES.find(
                                                    (level) =>
                                                        level.value === interests?.street_side
                                                )?.label
                                            }
                                        </li>
                                    )}
                                    <br />
                                    {interests?.from_val && (
                                        <li>
                                            Address Range - From : {interests?.from_val}
                                        </li>
                                    )}
                                    <br />
                                    {interests?.to_val && (
                                        <li>Address Range - To : {interests?.to_val}</li>
                                    )}
                                </>
                            )}
                    </ul>
                </ModalBody>
            </Modal>
            {showNotImplementedModal && (
                <NotYetImplemented close={() => setShowNotImplementedModal(false)} />
            )}
        </>
    );
}

export default MapView;
