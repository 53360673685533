import { Button, Form, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "../../api/auth.api";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

export function ResetPassword() {
  const [isDisabled, setIsDisabled] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&#.,%_-]{6,}$/,
        "Password must contain at least 6 characters, one uppercase letter, one lowercase letter and one number"
      ),
    confirmPassword: Yup.string()
      .min(6, "Confirm password must be at least 6 characters")
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    try {
      setIsDisabled(true);
      const response = await resetPassword(token, {
        password: data.password,
        confirmPassword: data.confirmPassword,
      });
      if (response && response.data && response.data.success) {
        toast.success(response.data?.message);
        navigate("/");
      } else {
        toast.error(response.data?.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.errors.length !== 0
          ? error?.response?.data?.errors[0]?.message
          : error.response.data.message
      );
    }
    setIsDisabled(false);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center remove-scroll">
        <Form role="form" onSubmit={handleSubmit(onSubmit)} className="w-25">
          <h1 className="py-2">Reset password</h1>
          <div className="form-group">
            <label>Password </label>
            <input
              name="password"
              type="password"
              disabled={isDisabled}
              {...register("password")}
              placeholder="Please enter password"
              className={`form-control ${errors.password ? "is-invalid" : ""}`}
            />
            <div className="invalid-feedback">{errors.password?.message}</div>
          </div>
          <div className="form-group">
            <label>Confirm password </label>
            <input
              name="confirmPassword"
              type="password"
              disabled={isDisabled}
              {...register("confirmPassword")}
              placeholder="Please enter confirm password"
              className={`form-control ${
                errors.confirmPassword ? "is-invalid" : ""
              }`}
            />
            <div className="invalid-feedback">
              {errors.confirmPassword?.message}
            </div>
          </div>

          <div>
            <Button color="primary" type="submit" disabled={isDisabled}>
              {isDisabled ? (
                <Spinner size="sm" className="mr-1">
                  Loading...
                </Spinner>
              ) : (
                ""
              )}{" "}
              <span>Reset your password</span>
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
}
