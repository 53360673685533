const isExactAddressValueChecker = (data) => {
    let exactAddressValue = data?.address_components?.find(
        (element) => element.types.includes("street_number")
    );
    if (exactAddressValue?.long_name) {
        return true;
    } else {
        return false;
    }
}

const isStreetValueChecker = (data) => {
    let exactAddressValue = data?.address_components?.find(
        (element) => element.types.includes("street_number")
    );
    let exactAddressValueRoute = data?.address_components?.find(
        (element) => element.types.includes("route")
    );
    if (!exactAddressValue?.long_name && exactAddressValueRoute) {
        return true;
    } else {
        return false;
    }
}

const isCityTownChecker = (data) => {
    let locality = data?.address_components?.find(
        (element) => element.types.includes("locality")
    );
    let route = data?.address_components?.find((element) =>
        element.types.includes("route")
    );
    let sub_locality = data?.address_components?.find(
        (element) => element.types.includes("sublocality")
    );
    let neighborhood = data?.address_components?.find(
        (element) => element.types.includes("neighborhood")
    );
    if (
        locality &&
        !route &&
        !sub_locality &&
        !neighborhood
    ) {
        return true;
    } else {
        return false;
    }
}


module.exports = {
    isExactAddressValueChecker,
    isStreetValueChecker,
    isCityTownChecker
}