const PROPERTY_MESSAGES = {
  CREATE: "Property create successfully.",
  UPDATE: "Property update successfully.",
  DELETE: "Property delete successfully.",
};

const INTEREST_MESSAGES = {
  CREATE: "Interest create successfully.",
  UPDATE: "Interest update successfully.",
  DELETE: "Interest delete successfully.",
};

const COMMON_MESSAGES = {
  INVALID_URL: "Please enter valid URL",
};

module.exports = {
  PROPERTY_MESSAGES,
  INTEREST_MESSAGES,
  COMMON_MESSAGES,
};
